import Service from '@/helpers/service'

export default {
	approval(urlApproval, key, param) {
		//approve, reject, reapprove, reject-item, revise-item, processs
        return Service().post('/mcr-approval-page/'+ urlApproval +'/'+key, param); 
	},
	appTracking(mcr_code){
		return Service().get('/emcr/log_approval/'+ mcr_code); 
	},
	process(params, id) {
        return Service().post('mcr-approval-page/process/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    statusWorkflow(mcr_code){
		return Service().get('statusWorkflow/'+ mcr_code); 
	},
	/*rjtMcrForm(mcr_code) {
        return Service().post('/mcr-approval-page/reject/'+mcr_code);
	},
	rvsMcrForm(mcr_code) {
        return Service().post('/mcr-approval-page/revise/'+mcr_code);
	},
	reAppMcrForm(mcr_code) {
        return Service().post('/mcr-approval-page/reapprove/'+mcr_code);
	},
	rjtMcrItem(mcr_item_code) {
        return Service().post('/mcr-approval-page/reject-item/'+mcr_item_code);
	},
	rvsMcrItem(mcr_item_code) {
        return Service().post('/mcr-approval-page/revise-item/'+mcr_item_code);
	},
	prsMcrItem(mcr_item_code) {
        return Service().post('/mcr-approval-page/process/'+mcr_item_code);
	}, 
	*/   
}
