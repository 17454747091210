import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('ScopeOfSupply?page=' + page, { params });
    },
    show(id) {
        return Service().get('ScopeOfSupply/show/' + id);
    },
    create(params) {
        return Service().post('ScopeOfSupply/create', params);
    },
    update(id, params) {
        return Service().post('ScopeOfSupply/update/' + id, params);
    },
    delete(id) {
        return Service().post('ScopeOfSupply/delete/' + id);
    },
    relSosMGroup(sosHeader) {
        return Service().get('ScopeOfSupply/rel-sos-mgroup/' + sosHeader);
    },
    sosByMGroup(materialGroupCode) {
        return Service().get('ScopeOfSupply/sos-by-mgroup/' + materialGroupCode);
    }
}