<template>
	<div v-if="requestCalls > 0">
		<skeleton-loading></skeleton-loading>
	</div>
	<div v-else>
		<div id="accordionEm">
	        <div class="card z-depth-0 bordered">
	          <div class="card-header bg-secondary border" id="headingOneBds">
	            <div class="row">
	              <div class="col-md-6">
	                <h5 class="mb-0">
	                  <a
	                    href="javascript:void(0)"
	                    @click="toggleAccordion(accordion.a3.em.show, 'em')"
	                    >{{ tt("extend_material") }}
	                    <span class="text-danger">*</span></a
	                  >
	                </h5>
	              </div>
	            </div>
	          </div>
		      	<div id="collapseEm" :class="'collapse ' + accordion.a3.em.show">
		            <div class="card-body border">
				      	<div class="row">
					        <div class="col-md-6">
					            <label class="form-control-label">{{ tt('material_number') }} <span class="text-danger">*</span></label>
					            <base-input :name="tt('material_number')" rules="required">
					            	<el-select v-model="inputDraftListA3.material_number" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 3})" :remote-method="getMaterialNumber" :loading="inputTo.loading" :disabled="form.a3.disabled">
			                            <el-option label="" value=""></el-option>
			                            <el-option v-for="mn in materialNumber" :key="mn.material_number" :label="mn.material_number +' - '+ mn.short_description" :value="mn.material_number"></el-option>
			                        </el-select>
					            </base-input>
					        </div>
					        <div class="col-md-6">
					            <label class="form-control-label">{{ tt('plant') }}</label>
					            <base-input :name="tt('plant')">
					            	<el-select v-model="inputDraftListA3.plant_code" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="getPlantCode" :loading="inputTo.loading" :disabled="form.a3.disabled">
				                        <el-option label="" value=""></el-option>
				                        <el-option v-for="pc in plantCode" :key="pc.plant_code" :label="pc.plant_code +' - '+ pc.description" :value="pc.plant_code"></el-option>
				                    </el-select>
					            </base-input>
					        </div>
					    </div>

					    <div class="row">
					        <div class="col-md-6">
					            <label class="form-control-label">{{ tt('storage_location') }}</label>
					            <base-input :name="tt('storage_location')">
					            	<el-select v-model="inputDraftListA3.location_code" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="getLocationCode" :loading="inputTo.loading" :disabled="form.a3.disabled">
				                        <el-option label="" value=""></el-option>
				                        <el-option v-for="lc in locationCode" :key="lc.location_code" :label="lc.location_code +' - '+ lc.location_description" :value="lc.location_code"></el-option>
				                    </el-select>
					            </base-input>
					        </div>
					    </div>
					</div>
				</div>
	      	</div>
	  	</div>

	    <!-- <div id="accordionMpnA3">
	        <div class="card z-depth-0 bordered">
	            <div class="card-header bg-secondary border" id="headingOneMpnA3">
	                <h4 class="pt-2 pl-2 pb-2 pr-2 mb--1">
	                    <div class="row">
	                        <div class="col-md-6">
	                        	<h5 class="mb-0">
	                            	<span @click="toggleAccordion(accordion.a3.mpn.show, 'mpnA3')">{{tt('manufacturer_part_number')}}</span>
	                        	</h5>
	                        </div>
	                        <div class="col-md-6 text-right">
	                            <base-button v-if="!form.a3.disabled" size="sm" type="default" @click="addMpnA3">{{ tt('add_new') }}</base-button>
	                        </div>
	                    </div>
	                </h4>
	            </div>
	            <div id="collapseMpnA3" :class="'collapse '+accordion.a3.mpn.show">
	                <div class="card-body border">
	                    <div class="table-responsive">
	                        <table class="table align-items-center table-flush table-bordered">
	                            <thead class="card-header bg-primary">
	                                <tr>
	                                    <th class="text-white">{{tt('manufacturer_code')}}</th>
	                                    <th class="text-white">{{tt('mpn')}}</th>
	                                    <th class="text-white">{{tt('type')}}</th>
	                                    <th class="text-white">{{tt('note')}}</th>
	                                    <th></th>
	                                </tr>
	                            </thead>
	                            <tbody>
	                                <tr v-for="(mpn, key) in manufacturePartNumberA3.manufacture_code" v-bind:key="mpn.manufacture_code">
	                                    <td>
	                                    	<el-select v-model="manufacturePartNumberA3.manufacture_code[key]" filterable remote reserve-keyword :placeholder="tt('choose_manufacture')" :remote-method="getManufacture" :loading="inputTo.loading" :disabled="form.a3.disabled">
                                                <el-option label="" value=""></el-option>
                                                <el-option v-for="mn in manufacture" :key="mn.manufacture_code" :label="mn.manufacture_code +' - '+ mn.manufacture_name" :value="mn.manufacture_code"></el-option>
                                            </el-select>
	                                    </td>
	                                    <td><input :disabled="form.a3.disabled" class="form-control" :name="tt('mpn')" :placeholder="tt('mpn')" v-model="manufacturePartNumberA3.mpn[key]" rules="required"></td>
	                                    <td>
	                                        <select :disabled="form.a3.disabled" class="form-control" v-model="manufacturePartNumberA3.manufacture_type[key]">
	                                                <option value="">Choose Type</option>
	                                            <option :value="mt.code" v-for="mt in manrefType" v-bind:key="mt.id">{{mt.description}}</option>
	                                        </select>
	                                    </td>
	                                    <td><input :disabled="form.a3.disabled" class="form-control" :name="tt('note')" :placeholder="tt('note')" v-model="manufacturePartNumberA3.manufacture_note[key]" rules="required"></td>
	                                    <td><i v-if="!form.a3.disabled" class="fa fa-trash text-danger" @click="removeMpnA3(key)"></i></td>
	                                </tr>
	                            </tbody>
	                        </table>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </div> -->

	   <!--  <div id="accordionEqiA3">
	        <div class="card z-depth-0 bordered">
	            <div class="card-header bg-secondary border" id="headingOneEqiA3">
	                <h4 class="pt-2 pl-2 pb-2 pr-2 mb--1">
	                    <div class="row">
	                        <div class="col-md-6">
	                        	<h5 class="mb-0">
	                            	<span @click="toggleAccordion(accordion.a3.eqi.show, 'eqiA3')">{{tt('equipment_&_quantity_install')}}</span>
	                            </h5>
	                        </div>
	                        <div class="col-md-6 text-right">
	                            <base-button v-if="!form.a3.disabled" size="sm" type="default" @click="addEqiA3">{{ tt('add_new') }}</base-button>
	                        </div>
	                    </div>
	                </h4>
	            </div>
	            <div id="collapseEqiA3" :class="'collapse '+accordion.a3.eqi.show">
	                <div class="card-body border">
	                    <div class="table-responsive">
	                        <table class="table align-items-center table-flush table-bordered">
	                            <thead class="card-header bg-primary">
	                                <tr>
	                                    <th class="text-white">{{tt('plant')}}</th>
	                                    <th class="text-white">{{tt('equipment_code')}}</th>
	                                    <th class="text-white">{{tt('quantity_install')}}</th>
	                                    <th class="text-white">{{tt('drawing_number')}}</th>
	                                    <th></th>
	                                </tr>
	                            </thead>
	                            <tbody>
	                                <tr v-for="(eqi, key) in equipmentQuantityInstallA3.equipment_code" v-bind:key="eqi.equipment_code">
	                                    <td>
	                                    	<el-select v-model="equipmentQuantityInstallA3.plant_equipment_code[key]" filterable remote reserve-keyword :placeholder="tt('choose_plant')" :remote-method="getPlantEquipmentCode" :loading="inputTo.loading" :disabled="form.a3.disabled">
						                        <el-option label="" value=""></el-option>
						                        <el-option v-for="pc in plantEquipmentCode" :key="pc.plant_code" :label="pc.plant_code +' - '+ pc.description" :value="pc.plant_code"></el-option>
						                    </el-select>
	                                    </td>
	                                    <td>
	                                    	<el-select style="width: 100%;" size="small" v-model="equipmentQuantityInstallA3.equipment_code[key]" filterable remote reserve-keyword :placeholder="tt('choose_equipment')" :remote-method="getEquipmentCode" :loading="inputTo.loading" :disabled="form.a3.disabled">
						                        <el-option label="" value=""></el-option>
						                        <el-option v-for="ec in equipmentCode" :key="ec.equipment_code" :label="ec.equipment_code +' - '+ ec.equipment_name" :value="ec.equipment_code"></el-option>
						                    </el-select>
	                                    </td>
	                                    <td><input :disabled="form.a3.disabled" class="form-control" :name="tt('qty_install')" :placeholder="tt('qty_install')" v-model="equipmentQuantityInstallA3.qty_installed[key]" rules="required"></td>
	                                    <td>
	                                        <select :disabled="form.a3.disabled" class="form-control" v-model="equipmentQuantityInstallA3.drawing_number[key]">
	                                            <option value="">Choose Type</option>
	                                            <option :value="dm.drawing_number" v-for="dm in drawingMaster" v-bind:key="dm.drawing_number">{{dm.drawing_name}}</option>
	                                        </select>
	                                    </td>
	                                    <td><i v-if="!form.a3.disabled" class="fa fa-trash text-danger" @click="removeEqiA3(key)"></i></td>
	                                </tr>
	                            </tbody>
	                        </table>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </div> -->

	    <label class="form-control-label mt-4">{{ tt('note') }} </label>
	    <base-input :name="tt('note')">
	        <textarea :disabled="form.a3.disabled" class="form-control" rows="5" v-model="inputDraftListA3.note"></textarea>
	    </base-input>

	    <div class="float-right">
	    	<base-button
	          type="primary"
	          @click="save('add')"
	          v-if="formType == 'add'"
	          :disabled="form.btnSave.onLoading">
	          <span v-if="form.btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
	          <span v-else>{{ tt("add") }}</span>
			</base-button
	        >
	        <base-button
	          type="primary"
	          @click="save('update')"
	          v-else-if="formType == 'edit'"
	          :disabled="form.btnSave.onLoading">
	          <span v-if="form.btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
	          <span v-else>{{ tt("edit") }}</span>
			</base-button>
	    </div>
	</div>
</template>
<script>
    import Api from '@/helpers/api';
    import draftList from '@/services/material/draftList.service';
    import materialNumber from '@/services/master/materialNumber.service';
    import locationCode from '@/services/master/locationCode.service';
    import valuationType from '@/services/master/valuationType.service';
    import plantCode from '@/services/master/plantCode.service';
    import manufacture from '@/services/master/manufacture.service';
    import manrefType from '@/services/master/manrefType.service';
    import equipmentCode from '@/services/master/equipmentCode.service';
    import drawingMaster from '@/services/dictionary/drawingMaster.service';

    export default {        
        data() {
            return { 
            	requestCalls: 0,
            	onLoad: true,
                form: {
                    a3: {
                        show: false,
                        disabled: false,
                        type: 'add',
                    },
                    btnSave: {
						onLoading: false
					}
                },
                inputTo: {
                    loading: false,
                    loadTimeout: null
                },
                inputDraftListA3: {
                    material_number: '',
                    plant_code: '',
                    location_code: '',
                    valuation_type_code: '',
                    note: '',
                },
                locationCode: {},
                valuationType: {},
                manufacture: {},
                manrefType: {},
                accordion: { 
                    a3: {
	                    em: {
							show: 'show'
						},
                        mpn: {
                            show: ''
                        },
                        eqi: {
                            show: ''
                        },
                    },
                },
                manufacturePartNumberA3: {
                    manufacture_code: [],
                    mpn: [],
                    manufacture_type: [],
                    manufacture_note: []
                },
                equipmentQuantityInstallA3: {
                    plant_equipment_code: [],
                    equipment_code: [],
                    qty_installed: [],
                    drawing_number: [],
                },
                materialNumber: [],
                plantCode: [],
                plantEquipmentCode: [],	
                equipmentCode: [],
                drawingMaster: [],
                data: {
                    search: {
                        material_number: [],
                        plant_code: [],
                    },
                }, 
                mcr_code: this.$route.params.mcr_code,
			    mcrItemCode: this.$route.params.mcr_item_code,
			    formType: this.$route.params.type
            }
        },
        mounted() {
        	if (this.formType == 'edit' || this.formType == 'detail' || this.formType == 'approval' || this.formType == 'approval-detail') {
				this.getDetailMcrItem();
				this.checkFormType();
			}
            this.getValuationType();
            this.links_material_number = this.materialNumber;
            this.links_plant_code = this.plantCode;
        },
        methods: {
        	checkFormType() {
				switch(this.formType) {
					case 'edit':
						this.form.a3.disabled = false;
						break;
					case 'detail':
						this.form.a3.disabled = true;
						break;
					case 'approval':
                        this.form.a3.disabled = true;
                        break;
                    case 'approval-detail':
                        this.form.a3.disabled = true;
                        break;
				}
			},
        	getDetailMcrItem() {
    //     		this.accordion.a3.mpn.show = "show";
				// this.accordion.a3.eqi.show = "show";
				this.requestCalls++;

                let context = this;               
                Api(context, draftList.getDetailMcrItem(this.mcrItemCode)).onSuccess(function(response) {    
                        context.inputDraftListA3 = response.data.data[0];
                        context.materialNumber[0] = {
				          	material_number: response.data.data[0].material_number,
				          	short_description: response.data.data[0].short_description,
				        };
                        context.plantCode[0] = {
				          	plant_code: (response.data.data[0].plant_code == null) ? '' : response.data.data[0].plant_code,
				          	description: (response.data.data[0].description == null) ? '' : response.data.data[0].description,
				        };
				        context.locationCode[0] = {
				          	location_code: (response.data.data[0].location_code == null) ? '' : response.data.data[0].location_code,
				          	location_description: (response.data.data[0].location_description == null) ? '' : response.data.data[0].location_description,
				        };
				        context.onLoad = false;                  
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.inputDraftListA3 = [];
                        }
                    }).onFinish(function(){
	                	context.requestCalls--;
	                }).call()

             //        Api(context, draftList.getMcrItemMpn(this.mcrItemCode)).onSuccess(function(response) {
             //            for (var i=0; i<response.data.data.length; i++) {
             //            	context.manufacture[i] = {
					        //   	manufacture_code: response.data.data[i].manufacture_code,
					        //   	manufacture_name: response.data.data[i].manufacture_name,
					        // }; 
             //                context.manufacturePartNumberA3.manufacture_code[i] = response.data.data[i].manufacture_code;
             //                context.manufacturePartNumberA3.mpn[i] = response.data.data[i].mpn;
             //                context.manufacturePartNumberA3.manufacture_type[i] = response.data.data[i].manufacture_type;
             //                context.manufacturePartNumberA3.manufacture_note[i] = response.data.data[i].manufacture_note;
             //            }                    
             //        }).onError(function(error) {                    
             //            if (error.response.status == 404) {
             //                context.manufacturePartNumberA3.manufacture_code = [];
             //                context.manufacturePartNumberA3.mpn = [];
             //                context.manufacturePartNumberA3.manufacture_type = [];
             //                context.manufacturePartNumberA3.manufacture_note = [];
             //            }
             //        }).call()

             //        Api(context, draftList.getMcrItemEquipment(this.mcrItemCode)).onSuccess(function(response) {
             //            for (var i=0; i<response.data.data.length; i++) {
             //            	context.plantEquipmentCode[i] = {
					        //   	plant_code: response.data.data[i].plant_code,
					        //   	description: response.data.data[i].description,
					        // };
             //            	context.equipmentCode[i] = {
					        //   	equipment_code: response.data.data[i].equipment_code,
					        //   	equipment_name: response.data.data[i].equipment_name,
					        // };
             //                context.equipmentQuantityInstallA3.plant_equipment_code[i] = response.data.data[i].plant_code;
             //                context.equipmentQuantityInstallA3.equipment_code[i] = response.data.data[i].equipment_code;
             //                context.equipmentQuantityInstallA3.qty_installed[i] = response.data.data[i].qty_installed;
             //                context.equipmentQuantityInstallA3.drawing_number[i] = response.data.data[i].drawing_number;
             //            }                        
             //        }).onError(function(error) {                    
             //            if (error.response.status == 404) {
             //                context.equipmentQuantityInstallA3.plant_equipment_code = [];
             //                context.equipmentQuantityInstallA3.equipment_code = [];
             //                context.equipmentQuantityInstallA3.qty_installed = [];
             //                context.equipmentQuantityInstallA3.drawing_number = [];
             //            }
             //        }).call()
            },
            getMaterialNumber(query) {                        
                if (query.length > 2) {
                    clearTimeout(this.inputTo.loadTimeout);             
                    let context = this;   
                    this.inputTo.loadTimeout = setTimeout(() => {
                        this.inputTo.loading = true;
                        Api(context,  materialNumber.get({search: query})).onSuccess(function(response) {
                            context.materialNumber = response.data.data;                        
                            context.inputTo.loading = false;                     
                        }).onError(function(error) {
                            context.materialNumber = [];
                            context.inputTo.loading = false;
                        }).call();             
                    })     
                }           
            },
            getPlantCode(query) {
		 		clearTimeout(this.inputTo.loadTimeout);             
                let context = this;   
                this.inputTo.loadTimeout = setTimeout(() => {
                    this.inputTo.loading = true;
                    Api(context, plantCode.companyPlant({per_page: 'none', search: query})).onSuccess(function(response) {
                        context.plantCode =  response.data.data;                                    
                        context.inputTo.loading = false;   
                    }).onError(function(error) {
                        if (error.response.status == 404) {
                            context.plantCode = [];
                            context.inputTo.loading = false;
                        }
                    }).call();             
                }) 
		    },
		    getPlantEquipmentCode(query) {
		 		clearTimeout(this.inputTo.loadTimeout);             
                let context = this;   
                this.inputTo.loadTimeout = setTimeout(() => {
                    this.inputTo.loading = true;
                    Api(context, plantCode.get('none', {search: query})).onSuccess(function(response) {
                        context.plantEquipmentCode =  response.data.data.data.data;                                    
                        context.inputTo.loading = false;   
                    }).onError(function(error) {
                        if (error.response.status == 404) {
                            context.plantEquipmentCode = [];
                            context.inputTo.loading = false;
                        }
                    }).call();             
                }) 
		    },
            getLocationCode(query) {
                clearTimeout(this.inputTo.loadTimeout);             
                let context = this;   
                this.inputTo.loadTimeout = setTimeout(() => {
                    this.inputTo.loading = true;
                    Api(context, locationCode.get(null, {per_page: 'none', search: query})).onSuccess(function(response) {
                        context.locationCode =  response.data.data.data.data;                                    
                        context.inputTo.loading = false;   
                    }).onError(function(error) {
                        if (error.response.status == 404) {
                            context.locationCode = [];
                            context.inputTo.loading = false;
                        }
                    }).call();             
                })
            },
            getValuationType() {
            	this.requestCalls++;
                let context = this;         
                      
                Api(context, valuationType.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.valuationType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.valuationType = [];
                    }
                }).onFinish(function(){
                	context.requestCalls--;
                }).call()
            },
            getManufacture(query) {
				clearTimeout(this.inputTo.loadTimeout);             
                let context = this;   
                this.inputTo.loadTimeout = setTimeout(() => {
                    this.inputTo.loading = true;
                    Api(context, manufacture.get({search: query})).onSuccess(function(response) {
                        context.manufacture =  response.data.data.data.data;                                    
                        context.inputTo.loading = false;   
                    }).onError(function(error) {
                        if (error.response.status == 404) {
                            context.manufacture = [];
                            context.inputTo.loading = false;
                        }
                    }).call();             
                })
		    },
            getManrefType() {
                let context = this;               
                Api(context, manrefType.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.manrefType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.manrefType = [];
                    }
                })
                .call()
            },
            getEquipmentCode(query) {
		  		clearTimeout(this.inputTo.loadTimeout);             
                let context = this;   
                this.inputTo.loadTimeout = setTimeout(() => {
                    this.inputTo.loading = true;
                    Api(context, equipmentCode.get('none', {search: query})).onSuccess(function(response) {
                        context.equipmentCode =  response.data.data.data.data;                                    
                        context.inputTo.loading = false;   
                    }).onError(function(error) {
                        if (error.response.status == 404) {
                            context.equipmentCode = [];
                            context.inputTo.loading = false;
                        }
                    }).call();             
                })
		    },
            getDrawingMaster() {
                let context = this;               
                Api(context, drawingMaster.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.drawingMaster = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.drawingMaster = [];
                    }
                })
                .call()
            },
            addMpnA3() {
            	this.accordion.a3.mpn.show = "show";

                if (this.manufacturePartNumberA3.manufacture_code.length == 0) {
                    this.manufacturePartNumberA3.manufacture_code.push('')
                    this.manufacturePartNumberA3.mpn.push('')
                    this.manufacturePartNumberA3.manufacture_type.push('')
                    this.manufacturePartNumberA3.manufacture_note.push('')
                } else if (this.manufacturePartNumberA3.manufacture_code.length > 0) {
                    if (this.manufacturePartNumberA3.manufacture_code[this.manufacturePartNumberA3.manufacture_code.length-1] != '' &&
                        this.manufacturePartNumberA3.mpn[this.manufacturePartNumberA3.mpn.length-1] != '' &&
                        this.manufacturePartNumberA3.manufacture_type[this.manufacturePartNumberA3.manufacture_type.length-1] != '' &&
                        this.manufacturePartNumberA3.manufacture_note[this.manufacturePartNumberA3.manufacture_note.length-1] != '') {
                        
                        this.manufacturePartNumberA3.manufacture_code.push('')
                        this.manufacturePartNumberA3.mpn.push('')
                        this.manufacturePartNumberA3.manufacture_type.push('')
                        this.manufacturePartNumberA3.manufacture_note.push('')
                    } else {
                        this.$notify({
                            message: this.tt("please_complete_data", { title: this.tt("mpn") }),                  
                            type: 'danger'
                        });
                    }
                }
            },
            addEqiA3() {
            	this.accordion.a3.eqi.show = "show";

                if (this.equipmentQuantityInstallA3.plant_equipment_code.length == 0) {
                    this.equipmentQuantityInstallA3.plant_equipment_code.push('')
                    this.equipmentQuantityInstallA3.equipment_code.push('')
                    this.equipmentQuantityInstallA3.qty_installed.push('')
                    this.equipmentQuantityInstallA3.drawing_number.push('')
                } else if (this.equipmentQuantityInstallA3.plant_equipment_code.length > 0) {
                    if (this.equipmentQuantityInstallA3.plant_equipment_code[this.equipmentQuantityInstallA3.plant_equipment_code.length-1] != '' &&
                        this.equipmentQuantityInstallA3.equipment_code[this.equipmentQuantityInstallA3.equipment_code.length-1] != '' &&
                        this.equipmentQuantityInstallA3.qty_installed[this.equipmentQuantityInstallA3.qty_installed.length-1] != '' &&
                        this.equipmentQuantityInstallA3.drawing_number[this.equipmentQuantityInstallA3.drawing_number.length-1] != '') {
                        
                        this.equipmentQuantityInstallA3.plant_equipment_code.push('')
                        this.equipmentQuantityInstallA3.equipment_code.push('')
                        this.equipmentQuantityInstallA3.qty_installed.push('')
                        this.equipmentQuantityInstallA3.drawing_number.push('')
                    } else {
                        this.$notify({
                            message: this.tt("please_complete_data", { title: this.tt("equipment_&_quantity_install") }),                  
                            type: 'danger'
                        });
                    }
                }
            },
            toggleAccordion(show, type) { 
                if (type == 'mpnA3') {
                    if (show == '') {
                        this.accordion.a3.mpn.show = 'show'
                    } else {
                        this.accordion.a3.mpn.show = ''
                    }
                } else if (type == 'eqiA3') {
                    if (show == '') {
                        this.accordion.a3.eqi.show = 'show'
                    } else {
                        this.accordion.a3.eqi.show = ''
                    }
                }else if(type == 'em'){
                	if (show == '') {
                        this.accordion.a3.em.show = 'show'
                    } else {
                        this.accordion.a3.em.show = ''
                    }
                }
            },
            save(save) {
                let context = this;       
                let data = {
                    mcr_code: context.mcr_code,
                    material_number: context.inputDraftListA3.material_number,
                    plant_code: context.inputDraftListA3.plant_code == '' ? null : context.inputDraftListA3.plant_code,
                    location_code: context.inputDraftListA3.location_code == '' ? null : context.inputDraftListA3.location_code,
                    note: context.inputDraftListA3.note,
                }
                
                if (context.inputDraftListA3.material_number != '') {

                    // for (var i=0; i<context.manufacturePartNumberA3.manufacture_code.length; i++) {
                    //     if (context.manufacturePartNumberA3.manufacture_code[i] != '' &&
                    //         context.manufacturePartNumberA3.mpn[i] != '' &&
                    //         context.manufacturePartNumberA3.manufacture_type[i] != '' &&
                    //         context.manufacturePartNumberA3.manufacture_note[i] != '') {
                            

                    //         data.manufacture_code = context.manufacturePartNumberA3.manufacture_code;
                    //         data.mpn = context.manufacturePartNumberA3.mpn;
                    //         data.manufacture_type = context.manufacturePartNumberA3.manufacture_type;
                    //         data.manufacture_note = context.manufacturePartNumberA3.manufacture_note;

                    //     } else {
                    //         context.$notify({
                    //             message: this.tt("please_complete_data", { title: this.tt("mpn") }),                  
                    //             type: 'danger'
                    //         });
                    //         return false;
                    //     }
                    // }
                    
                    // for (var i=0; i<context.equipmentQuantityInstallA3.plant_equipment_code.length; i++) {
                    //     if (context.equipmentQuantityInstallA3.plant_equipment_code[i] != '' &&
                    //         context.equipmentQuantityInstallA3.equipment_code[i] != '' &&
                    //         context.equipmentQuantityInstallA3.qty_installed[i] != '' &&
                    //         context.equipmentQuantityInstallA3.drawing_number[i] != '') {
                            
                    //         data.plant_equipment_code = context.equipmentQuantityInstallA3.plant_equipment_code;
                    //         data.equipment_code = context.equipmentQuantityInstallA3.equipment_code;
                    //         data.qty_installed = context.equipmentQuantityInstallA3.qty_installed;
                    //         data.drawing_number = context.equipmentQuantityInstallA3.drawing_number;

                    //     } else {
                    //         context.$notify({
                    //             message: this.tt("please_complete_data", { title: this.tt("equipment_&_quantity_install") }),                  
                    //             type: 'danger'
                    //         });
                    //         return false;
                    //     }
                    // }

                    // data.note = context.inputDraftListA3.note;
                    
                   	context.form.btnSave.onLoading = true;
                    let api = '';
                    if (save == 'add') {
                        api = Api(context, draftList.createNewMcrItemNoFormData(data));
                    } else {
                        api = Api(context, draftList.updateMcrItemNoFormData(data, context.inputDraftListA3.id));
                    }
                    api.onSuccess(function(response) {    
                        context.$notify({
                            message: response.data.message,                  
                            type: 'success',
                            verticalAlign: 'bottom', 
							horizontalAlign: 'left'
                        });
                        context.form.btnSave.onLoading = false;
                        context.$router.back();
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.draftList = [];
                            context.form.btnSave.onLoading = false;
                        }
                    })
                    .call()
                } else {
                    context.$notify({
                        message: this.tt("please_complete_data", { title: this.tt("extend_material") }),          
                        type: 'danger'
                    });
                }
            },
        }   
    };
</script>