<template>
	<div v-if="requestCalls > 0">
		<skeleton-loading></skeleton-loading>
	</div>
	<div v-else>
		<div id="accordionBds">
	        <div class="card z-depth-0 bordered">
	          <div class="card-header bg-secondary border" id="headingOneBds">
	            <div class="row">
	              <div class="col-md-6">
	                <h5 class="mb-0">
	                  <a
	                    href="javascript:void(0)"
	                    @click="toggleAccordion(accordion.bds.show, 'bds')"
	                    >{{ tt("basic_data_&_specification") }}
	                    <span class="text-danger">*</span></a
	                  >
	                </h5>
	              </div>
	            </div>
	          </div>
	          <div id="collapseBds" :class="'collapse ' + accordion.bds.show">
	            <div class="card-body border">
	              <div class="row">
	                <div class="col-md-6">
	                  <label class="form-control-label"
	                    >{{ tt("material_type") }}
	                    <span class="text-danger">*</span></label
	                  >
	                  <base-input :name="tt('material_type')" rules="required">
	                    <el-select
	                      :disabled="form.disabled"
	                      class="select-danger"
	                      v-model="input.mtype_code"
	                      :placeholder="tt('choose_material_type')"
	                    >
	                      <el-option
	                        class="select-danger"
	                        :value="mt.code"
	                        :label="mt.code + ' - ' + mt.description"
	                        :key="mt.code"
	                        v-for="mt in materialType"
	                      ></el-option>
	                    </el-select>
	                  </base-input>
	                </div>
	                <div class="col-md-6">
	                  <label class="form-control-label"
	                    >{{ tt("item_type") }}
	                    <span class="text-danger">*</span></label
	                  >
	                  <base-input :name="tt('item_type')" rules="required">
	                    <el-select
	                      :disabled="form.disabled"
	                      class="select-danger"
	                      v-model="input.item_type_code"
	                      :placeholder="tt('choose_item_type')"
	                    >
	                      <el-option
	                        class="select-danger"
	                        :value="it"
	                        :label="it"
	                        :key="it"
	                        v-for="it in itemType"
	                      ></el-option>
	                    </el-select>
	                  </base-input>
	                </div>
	              </div>
	              <div class="row">
	                <div class="col-md-6">
	                  <label class="form-control-label"
	                    >{{ tt("plant") }} <span class="text-danger">*</span></label 
	                  >
	                  <base-input :name="tt('plant')" rules="required">
	                  	<el-select v-model="input.plant_code" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="getPlantCode" :loading="inputTo.loading" :disabled="form.disabled">
	                        <el-option label="" value=""></el-option>
	                        <el-option v-for="pc in plantCode" :key="pc.plant_code" :label="pc.plant_code +' - '+ pc.description" :value="pc.plant_code"></el-option>
	                    </el-select>
	                  </base-input>
	                </div>
	                <div class="col-md-6">
	                  <label class="form-control-label"
	                    >{{ tt("fabric_or_non_fabrication") }}
	                    <span class="text-danger">*</span></label
	                  >
	                  <base-input
	                    :name="tt('fabric_or_non_fabrication')"
	                    rules="required"
	                  >
	                    <el-select
	                      :disabled="form.disabled"
	                      class="select-danger"
	                      v-model="input.fab_non_fab"
	                      :placeholder="tt('choose_fabric_or_non_fabrication')"
	                    >
	                      <el-option
	                        class="select-danger"
	                        :value="fnf"
	                        :label="fnf"
	                        :key="fnf"
	                        v-for="fnf in fabNonFab"
	                      ></el-option>
	                    </el-select>
	                  </base-input>
	                </div>
	              </div>
	              <div class="row">
	                <div class="col-md-6">
	                  <label class="form-control-label"
	                    >{{ tt("uom") }} <span class="text-danger">*</span></label
	                  >
	                  <base-input :name="tt('uom')" rules="required">
	                  	<el-select v-model="input.uom_code" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="getUnitOfMeasure" :loading="inputTo.loading" :disabled="form.disabled">
	                        <el-option label="" value=""></el-option>
	                        <el-option v-for="uc in unitOfMeasure" :key="uc.code" :label="uc.code +' - '+ uc.description" :value="uc.code"></el-option>
	                    </el-select>
	                  </base-input>
	                </div>
	                <div class="col-md-6">
	                  <label class="form-control-label"
	                    >{{ tt("bom_or_non_bom") }}
	                    <span class="text-danger">*</span></label
	                  >
	                  <base-input :name="tt('bom_or_non_bom')" rules="required">
	                    <el-select
	                      :disabled="form.disabled"
	                      class="select-danger"
	                      v-model="input.bom_non_bom"
	                      :placeholder="tt('choose_bom_or_non_bom')"
	                    >
	                      <el-option
	                        class="select-danger"
	                        :value="bnb"
	                        :label="bnb"
	                        :key="bnb"
	                        v-for="bnb in bomNonBom"
	                      ></el-option>
	                    </el-select>
	                  </base-input>
	                </div>
	              </div>
	              <div class="row">
	                <div class="col-md-6">
	                  <label class="form-control-label"
	                    >{{ tt("critical_non_critical") }}
	                    <span class="text-danger">*</span></label
	                  >
	                  <base-input
	                    :name="tt('critical_non_critical')"
	                    rules="required"
	                  >
	                    <el-select
	                      :disabled="form.disabled"
	                      class="select-danger"
	                      v-model="input.critical_non_critical"
	                      :placeholder="tt('choose_critical_non_critical')"
	                    >
		                    <el-option
		                        class="select-danger"
		                        :value="cnc"
		                        :label="cnc"
		                        :key="cnc"
		                        v-for="cnc in criticalNonCritical"
		                    ></el-option>
	                    </el-select>
	                  </base-input>
	                </div>
	              </div>
	              <label class="form-control-label"
	                >{{ tt("item_name") }} <span class="text-danger">*</span></label
	              >
	              <base-input
	                :disabled="form.disabled"
	                :name="tt('item_name')"
	                :placeholder="tt('item_name')"
	                v-model="input.item_name"
	                rules="required"
	              ></base-input>

	              <label class="form-control-label mt-4"
	                >{{ tt("specification") }}
	                <span class="text-danger">*</span></label
	              >
	              <base-input :name="tt('specification')" rules="required">
	                <textarea
	                  :disabled="form.disabled"
	                  class="form-control"
	                  rows="5"
	                  v-model="input.specification"
	                ></textarea>
	              </base-input>
	            </div>
	          </div>
	        </div>
	      </div>

	      <!-- ------------------------ MANUFACTURE -------------------------- -->
	      <div id="accordionMpn">
	        <div class="card z-depth-0 bordered">
	          <div class="card-header bg-secondary border" id="headingOneMpn">
	            <div class="row">
	              <div class="col-md-6">
	                <h5 class="mb-0">
	                  <a
	                    href="javascript:void(0)"
	                    @click="toggleAccordion(accordion.mpn.show, 'mpn')"
	                    >{{ tt("manufacturer_part_number") }}</a
	                  >
	                </h5>
	              </div>
	              <div class="col-md-6 text-right">
	                <base-button
	                  v-if="!form.disabled"
	                  size="sm"
	                  type="default"
	                  @click="addMpn">
	                  {{ tt("add_new") }}
	                  </base-button>
	              </div>
	            </div>
	          </div>
	          <div id="collapseMpn" :class="'collapse ' + accordion.mpn.show">
	            <div class="card-body border">
	              <table
	                class="table align-items-center table-flush table-bordered"
	              >
	                <thead class="card-header bg-primary">
	                  <tr>
	                    <th width="500" class="text-white">{{ tt("manufacturer_code") }}</th>
	                    <th class="text-white">{{ tt("manufacture_ref") }}</th>
	                    <th class="text-white">{{ tt("source_type") }}</th>
	                    <th class="text-white" width="15%">{{ tt("type") }}</th>
	                    <th class="text-white">{{ tt("note") }}</th>
	                    <th></th>
	                  </tr>
	                </thead>
	                <tbody>
	                  <tr
	                    v-for="(mpn, key) in manufacturePartNumber.manufacture_code"
	                    v-bind:key="mpn.manufacture_code"
	                  >
	                    <td>
	                    	<el-select style="width: 100%;" v-model="manufacturePartNumber.manufacture_code[key]" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 4})" :remote-method="getManufacture" :loading="inputTo.loading" :disabled="form.disabled">
		                        <el-option label="" value=""></el-option>
		                        <el-option v-for="mn in manufacture" :key="mn.manufacture_code" :label="mn.manufacture_code +' - '+ mn.manufacture_name" :value="mn.manufacture_code"></el-option>
		                    </el-select>
	                    </td>
	                    <td>
	                      <input
	                      	:disabled="form.disabled"
	                        class="form-control"
	                        :name="tt('mpn')"
	                        :placeholder="tt('mpn')"
	                        v-model="manufacturePartNumber.mpn[key]"
	                        rules="required"/>
	                    </td>
	                    <td>
	                      <select :disabled="form.disabled" class="form-control" v-model="manufacturePartNumber.source_type[key]">
	                        <option value="">Choose Source Type</option>
	                        <option :value="st.code" v-for="st in sourceType" v-bind:key="st.id">{{ st.code +' - '+ st.description }}</option>
	                      </select>
	                    </td>
	                    <td>
	                      <select :disabled="form.disabled" class="form-control" v-model="manufacturePartNumber.manufacture_type[key]">
	                        <option value="">Choose Type</option>
	                        <option :value="mt.code" v-for="mt in manrefType" v-bind:key="mt.id">{{ mt.description }}</option>
	                      </select>
	                    </td>
	                    <td>
	                      <input
	                      	:disabled="form.disabled"
	                        class="form-control"
	                        :name="tt('note')"
	                        :placeholder="tt('note')"
	                        v-model="manufacturePartNumber.manufacture_note[key]"
	                        rules="required"
	                        style='width: 100px;'
	                      />
	                    </td>
	                    <td>
	                      <i v-if="!form.disabled" class="fa fa-trash text-danger" @click="removeMpn(key)"></i>
	                    </td>
	                  </tr>
	                </tbody>
	              </table>
	            </div>
	          </div>
	        </div>
	      </div>

	     <!-- --------------------- EQUIPMENT & QUANTITY INSTALL ---------------  -->
	      <div id="accordionEqi">
	        <div class="card z-depth-0 bordered">
	          <div class="card-header bg-secondary border" id="headingOneEqi">
	            <div class="row">
	              <div class="col-md-6">
	                <h5>
	                  <a
	                    href="javascript:void(0)"
	                    @click="toggleAccordion(accordion.eqi.show, 'eqi')"
	                    >{{ tt("equipment_&_quantity_install") }}</a>
	                    <span class="text-danger" v-if= "input.bom_non_bom == 'BOM' || input.item_type_code == 'OEM'"> *</span>
	                </h5>
	              </div>
	              <div class="col-md-6 text-right">
	                <base-button
	                  v-if="!form.disabled"
	                  size="sm"
	                  type="default"
	                  @click="addEqi"
	                  >{{ tt("add_new") }}</base-button
	                >
	              </div>
	            </div>
	          </div>
	          <div id="collapseEqi" :class="'collapse ' + accordion.eqi.show">
	            <div class="card-body border">
	              <table
	                class="table align-items-center table-flush table-bordered"
	              >
	                <thead class="card-header bg-primary">
	                  <tr>
	                    <th width="500"class="text-white">{{ tt("equipment_code") }}</th>
	                    <th width="200" class="text-white">
	                      {{ tt("quantity_install") }}
	                    </th>
	                    <th width="200" class="text-white">
	                      {{ tt("drawing_number") }}
	                    </th>
	                    <th width="200" class="text-white">
	                      {{ tt("attachment") }}
	                    </th>
	                    <th width="1"></th>
	                  </tr>
	                </thead>
	                <tbody>
	                  <tr
	                    v-for="(eqi,
	                    key) in equipmentQuantityInstall.equipment_code"
	                    v-bind:key="eqi.equipment_code"
	                  >
	                    <td>
	                    	<el-select style="width: 100%;" size="small" v-model="equipmentQuantityInstall.equipment_code[key]" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 4})" :remote-method="getEquipmentCode" :loading="inputTo.loading" :disabled="form.disabled">
		                        <el-option label="" value=""></el-option>
		                        <el-option v-for="ec in equipmentCode" :key="ec.equipment_code" :label="ec.equipment_code +' - '+ ec.equipment_name" :value="ec.equipment_code"></el-option>
		                    </el-select>
	                    </td>
	                    <td>
	                      <input
	                      	:disabled="form.disabled"
	                        class="form-control"
	                        :name="tt('qty_install')"
	                        :placeholder="tt('qty_install')"
	                        v-model="equipmentQuantityInstall.qty_installed[key]"
	                        rules="required"/>
	                    </td>
	                    <td>
	                    	<el-select style="width: 100%;" size="small" v-model="equipmentQuantityInstall.drawing_number[key]" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="getDrawingMaster" :loading="inputTo.loading" :disabled="form.disabled" @change="dmAttachment(key)">
		                        <el-option v-for="dm in drawingMaster" :key="dm.drawing_number" :label="dm.drawing_number +' - '+ dm.drawing_name" :value="dm.drawing_number"></el-option>
		                    </el-select>
	                    </td>
	                    <td>
	                    	<a :href="apiUrl+'drawing_master/'+dm_attachment[key]" target="_blank">{{dm_attachment[key]}}</a>
	                    </td>
	                    <td>
	                      <i v-if="!form.disabled" class="fa fa-trash text-danger" @click="removeEqi(key)"></i>
	                    </td>
	                  </tr>
	                </tbody>
	              </table>
	            </div>
	          </div>
	        </div>
	      </div>

	      <!-- ---------------------------- ATTACHMENT ------------------------- -->
	      <div id="accordionAd">
	        <div class="card z-depth-0 bordered">
	          <div class="card-header bg-secondary border" id="headingOneAd">
	            <div class="row">
	              <div class="col-md-6">
	                <h5>
	                  <a
	                    href="javascript:void(0)"
	                    @click="toggleAccordion(accordion.ad.show, 'ad')"
	                    >{{ tt("attachment_document") }}
	                    <span class="text-danger">*</span></a
	                  >
	                </h5>
	              </div>
	              <div class="col-md-6 text-right">
	                <base-button
	                  v-if="!form.disabled"
	                  size="sm"
	                  type="default"
	                  @click="addAd"
	                  >{{ tt("add_new") }}</base-button
	                >
	              </div>
	            </div>
	          </div>
	          <div id="collapseAd" :class="'collapse ' + accordion.ad.show">
	            <div class="card-body border">
	              <div class="table-responsive">
	                <table
	                  class="table align-items-center table-flush table-bordered"
	                >
	                  <thead class="card-header bg-primary">
	                    <tr>
	                      <th class="text-white">{{ tt("file_name") }}</th>
	                      <th class="text-white">{{ tt("description") }}</th>
	                      <th width="1"></th>
	                    </tr>
	                  </thead>
	                  <tbody>

	                  	<!-- PREVIEW KETIKA EDIT -->
	                  	<tr v-for="att in prevAttachmentDocument">
	                  		<td>
	                  			<a :href="apiUrl+'smartcat_attachment/'+att.file_attachment_name" target="_blank">{{att.file_attachment_name}}</a>
	                  		</td>
	                  		<td>
	                  			<span>{{att.description_attachment}}</span>
	                  		</td>
	                  		<td>
	                  			<a href="javascript:void(0)" v-if="!form.disabled" @click="removePrevAd(att.id)"><i class="fa fa-trash text-danger"></i></a>
	                  		</td>
	                  	</tr>

	                  	<!-- INPUTAN ATTACHMENT -->
	                    <tr v-for="(ad, key) in attachmentDocument.file_attachment_name" v-bind:key="ad.file_name">
	                      <td>
	                        <file-input
	                          :disabled="form.disabled"
	                          @change="changeFileAttachmentName"
	                          :initialLabel="ad.file_attachment_name"
	                          :ref="'file' + key"
	                          accept="application/pdf"
	                        ></file-input>
	                      </td>
	                      <td>
	                      	<input :disabled="form.disabled" class="form-control" :name="tt('description')" :placeholder="tt('description')" v-model="attachmentDocument.description_attachment[key]" rules="required">
	                      </td>
	                      <td>
	                        <a href="javascript:void(0)" v-if="!form.disabled" @click="removeAd(key)"><i class="fa fa-trash text-danger"></i></a>
	                      </td>
	                    </tr>
	                  </tbody>
	                </table>
	              </div>
	            </div>
	          </div>
	        </div>
	      </div>

		<label class="form-control-label mt-4">{{ tt("note") }} <span class="text-danger">*</span></label>
		<base-input :name="tt('note')" rules="required">
			<textarea
			  :disabled="form.disabled"
			  class="form-control"
			  rows="5"
			  v-model="input.note">
			</textarea>
		</base-input>

        <div class="float-right">
        	<base-button
	          type="primary"
	          @click="save('add')"
	          v-if="formType == 'add'"
	          :disabled="form.btnSave.onLoading">
	          <span v-if="form.btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
	          <span v-else>{{ tt("add") }}</span>
			</base-button
	        >
	        <base-button
	          type="primary"
	          @click="save('update')"
	          v-else-if="formType == 'edit'"
	          :disabled="form.btnSave.onLoading">
	          <span v-if="form.btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
	          <span v-else>{{ tt("edit") }}</span>
			</base-button>
        </div>
	</div>
</template>

<script>
	import Api from "@/helpers/api";
	import draftList from "@/services/material/draftList.service";
	import materialType from "@/services/master/materialType.service";
	import plantCode from "@/services/master/plantCode.service";
	import unitOfMeasure from "@/services/dictionary/unitOfMeasure.service";
	import manufacture from "@/services/master/manufacture.service";
	import manrefType from "@/services/master/manrefType.service";
	import equipmentCode from "@/services/master/equipmentCode.service";
	import drawingMaster from "@/services/dictionary/drawingMaster.service";
	import sourceType from "@/services/master/sourceType.service";
	import baseApiUrl from '@/configs/config';

	export default {
		data() {
			return {
				requestCalls: 0,
				accordion: {
			        bds: {
						show: "show"
					},
					mpn: {
						show: ""
					},
					eqi: {
						show: ""
					},
					ad: {
						show: ""
					}
		      	},
		      	form: {
					disabled: false,
					btnSave: {
						onLoading: false
					}
			    },
			    inputTo: {
                    loading: false,
                    loadTimeout: null
                },
			    materialType: {},
			    itemType: {},
			    fabNonFab: {},
			    bomNonBom: {},
			    criticalNonCritical: {},
			    manrefType: {},
			    sourceType: {},
			    drawingMaster: {},
			    plantCode: {},
				unitOfMeasure: {},
				manufacture: [],
				equipmentCode: [],
				fabNonFab: {},
				input: {
			        mtype_code: "",
			        item_type_code: "",
			        plant_code: "",
			        fab_non_fab: "",
			        uom_code: "",
			        uom_description: "",
			        bom_non_bom: "",
			        item_name: "",
			        specification: "",
			        critical_non_critical: "",
			        note: ""
			    },
			    manufacturePartNumber: {
			        manufacture_code: [],
			        manufacture_code_view: [],
			        mpn: [],
			        source_type: [],
			        manufacture_type: [],
			        manufacture_note: []
			    },
			    equipmentQuantityInstall: {
			        equipment_code: [],
			        equipment_code_view: [],
			        qty_installed: [],
			        drawing_number: []
			    },
			    attachmentDocument: {
			        file_attachment_name: [],
			        description_attachment: []
			    },
			    prevAttachmentDocument: {},
			    apiUrl: baseApiUrl.storageUrl,
			    dm_attachment: [],

			    mcr_code: this.$route.params.mcr_code,
			    mcrItemCode: this.$route.params.mcr_item_code,
			    formType: this.$route.params.type,
			    token: this.$route.params.token,
			}
		},
		mounted() {
			this.checkFormType();
			if (this.formType == 'edit' || this.formType == 'detail' || this.formType == 'approval' || this.formType == 'approval-detail') {
				this.getDetailMcrItem();
			} 
			// else if(this.formType == 'add'){
			// 	this.addAd();
			// }
			this.getParameterMcr();
			this.getMaterialType();
		    this.getManrefType();
		    this.getSourceType();
		},
		methods: {
			// CHECK FORM TYPE
			checkFormType() {
				switch(this.formType) {
					case 'edit':
						this.form.disabled = false;
						break;
					case 'detail':
						this.form.disabled = true;
						break;
					case 'approval':
						this.form.disabled = true;
						break;
					case 'approval-detail':
						this.form.disabled = true;
						break;
				}
			},

			// GET DATA MCR
			getDetailMcrItem() {
				this.requestCalls++;
				
				// show all accordion
				this.accordion.mpn.show = "show";
				this.accordion.eqi.show = "show";
				this.accordion.ad.show = "show";

		      	let context = this;

		      	Api(context, draftList.getDetailMcrItem(this.mcrItemCode))
		        .onSuccess(function(response) {
		          context.input = response.data.data[0];
		          context.plantCode[0] = {
		          	plant_code: response.data.data[0].plant_code,
		          	description: response.data.data[0].description,
		          };

		          context.unitOfMeasure[0] = {
		          	code: response.data.data[0].uom_code,
		          	description: response.data.data[0].uom_description,
		          }
		        })
		        .onError(function(error) {
		          if (error.response.status == 404) {
		            context.input = [];
		          }
		        })
		        .call();

		        // <!-- ------------------------ MANUFACTURE -------------------------- -->
				Api(context, draftList.getMcrItemMpn(this.mcrItemCode))
				.onSuccess(function(response) {
				  for (var i = 0; i < response.data.data.length; i++) {
				  	context.manufacture[i] = response.data.data[i];
				    context.manufacturePartNumber.manufacture_code[i] = response.data.data[i].manufacture_code;
				    context.manufacturePartNumber.manufacture_code_view[i] = response.data.data[i].manufacture_code +' - '+ response.data.data[i].manufacture_name;
				    context.manufacturePartNumber.mpn[i] = response.data.data[i].mpn;
				    context.manufacturePartNumber.source_type[i] = response.data.data[i].source_type;
				    context.manufacturePartNumber.manufacture_type[i] = response.data.data[i].manufacture_type;
				    context.manufacturePartNumber.manufacture_note[i] = response.data.data[i].manufacture_note;
				  }
				})
				.onError(function(error) {
				  if (error.response.status == 404) {
				    context.manufacturePartNumber.manufacture_code = [];
				    context.manufacturePartNumber.mpn = [];
				    context.manufacturePartNumber.source_type = [];
				    context.manufacturePartNumber.manufacture_type = [];
				    context.manufacturePartNumber.manufacture_note = [];
				  }
				})
				.call();

				// <!-- --------------------- EQUIPMENT & QUANTITY INSTALL ---------------  -->
				Api(context, draftList.getMcrItemEquipment(this.mcrItemCode))
				.onSuccess(function(response) {
				  for (var i = 0; i < response.data.data.length; i++) {
				  	context.equipmentCode[i] = response.data.data[i];
				    context.equipmentQuantityInstall.equipment_code[i] = response.data.data[i].equipment_code;
				    context.equipmentQuantityInstall.equipment_code_view[i] = response.data.data[i].equipment_code +' - '+ response.data.data[i].equipment_name;
				    context.equipmentQuantityInstall.qty_installed[i] = response.data.data[i].qty_installed;
				    context.equipmentQuantityInstall.drawing_number[i] = response.data.data[i].drawing_number;
				    context.drawingMaster[i] = {
			          	drawing_number: response.data.data[i].drawing_number,
			          	drawing_name: response.data.data[i].drawing_name,
			        };
			        context.dmAttachment(i);
				  }
				})
				.onError(function(error) {
				  if (error.response.status == 404) {
				    context.equipmentQuantityInstall.equipment_code = [];
				    context.equipmentQuantityInstall.qty_installed = [];
				    context.equipmentQuantityInstall.drawing_number = [];
				  }
				})
				.call();

				// <!-- ---------------------------- ATTACHMENT ------------------------- -->
				Api(context, draftList.getMcrItemAttachment(this.mcrItemCode))
				.onSuccess(function(response) {
					context.prevAttachmentDocument = response.data.data;
				})
				.onError(function(error) {
				  if (error.response.status == 404) {
				    context.prevAttachmentDocument = [];
				  }
				}).onFinish(function() {
                	context.requestCalls--;
                }).call()
		    },
		    getParameterMcr() {
		    	this.requestCalls++;
		    	let context = this;

		    	Api(context, draftList.getParameterMcr(this.mcrItemCode))
		        .onSuccess(function(response) {
					context.itemType            = response.data.data.item_type;
					context.fabNonFab           = response.data.data.fab_non_fab;
					context.bomNonBom           = response.data.data.bom_non_bom;
					context.criticalNonCritical = response.data.data.critical_non_critical;
		        })
		        .onError(function(error) {
		          if (error.response.status == 404) {
		            context.input = [];
		          }
		        }).onFinish(function(){
                	context.requestCalls--;
                }).call();
		    },
		    getMaterialType() {
		    	this.requestCalls++;
				let context = this;

				Api(context, materialType.get(null, { per_page: "none" }))
				.onSuccess(function(response) {
				  context.materialType = response.data.data.data.data;
				})
				.onError(function(error) {
				  if (error.response.status == 404) {
				    context.materialType = [];
				  }
				}).onFinish(function(){
                	context.requestCalls--;
                }).call();
		    },
		    getPlantCode(query) {
		 		clearTimeout(this.inputTo.loadTimeout);             
                let context = this;   
                this.inputTo.loadTimeout = setTimeout(() => {
                    this.inputTo.loading = true;
                    Api(context, plantCode.companyPlant({search: query})).onSuccess(function(response) {
                        context.plantCode =  response.data.data;                                    
                        context.inputTo.loading = false;   
                    }).onError(function(error) {
                        if (error.response.status == 404) {
                            context.plantCode = [];
                            context.inputTo.loading = false;
                        }
                    }).call();             
                }) 
		    },
		    getUnitOfMeasure(query) {
				clearTimeout(this.inputTo.loadTimeout);             
                let context = this;   
                this.inputTo.loadTimeout = setTimeout(() => {
                    this.inputTo.loading = true;
                    Api(context, unitOfMeasure.get(null, {per_page: 'none', search: query})).onSuccess(function(response) {
                        context.unitOfMeasure =  response.data.data.data.data;                                    
                        context.inputTo.loading = false;   
                    }).onError(function(error) {
                        if (error.response.status == 404) {
                            context.unitOfMeasure = [];
                            context.inputTo.loading = false;
                        }
                    }).call();             
                }) 
		    },
		    getManufacture(query) {
		    	if (query.length > 3) {
					clearTimeout(this.inputTo.loadTimeout);             
	                let context = this;   
	                this.inputTo.loadTimeout = setTimeout(() => {
	                    this.inputTo.loading = true;
	                    Api(context, manufacture.get({per_page: 'none', search: query})).onSuccess(function(response) {
	                        context.manufacture =  response.data.data.data.data;                                    
	                        context.inputTo.loading = false;   
	                    }).onError(function(error) {
	                        if (error.response.status == 404) {
	                            context.manufacture = [];
	                            context.inputTo.loading = false;
	                        }
	                    }).call();             
	                })
            	}
		    },
		    getManrefType() {
		    	this.requestCalls++;
				let context = this;

				Api(context, manrefType.get(null, { per_page: "none" }))
				.onSuccess(function(response) {
				  context.manrefType = response.data.data.data.data;
				})
				.onError(function(error) {
				  if (error.response.status == 404) {
				    context.manrefType = [];
				  }
				}).onFinish(function(){
                	context.requestCalls--;
                }).call();
		    },
		    getSourceType() {
		    	this.requestCalls++;
				let context = this;

				Api(context, sourceType.get(null, { per_page: "none" }))
				.onSuccess(function(response) {
				  context.sourceType = response.data.data.data.data;
				})
				.onError(function(error) {
				  if (error.response.status == 404) {
				    context.sourceType = [];
				  }
				}).onFinish(function(){
                	context.requestCalls--;
                }).call();
		    },
		    getEquipmentCode(query) {
		    	if (query.length > 3) {
			  		clearTimeout(this.inputTo.loadTimeout);             
	                let context = this;   
	                this.inputTo.loadTimeout = setTimeout(() => {
	                    this.inputTo.loading = true;
	                    Api(context, equipmentCode.get(null, {per_page: 'none', search: query})).onSuccess(function(response) {
	                        context.equipmentCode =  response.data.data.data.data;                                    
	                        context.inputTo.loading = false;   
	                    }).onError(function(error) {
	                        if (error.response.status == 404) {
	                            context.equipmentCode = [];
	                            context.inputTo.loading = false;
	                        }
	                    }).call();             
	                })
	            }
		    },
		    getDrawingMaster(query) {
		   		clearTimeout(this.inputTo.loadTimeout);             
                let context = this;   
                this.inputTo.loadTimeout = setTimeout(() => {
                    this.inputTo.loading = true;
                    Api(context, drawingMaster.get(null, {per_page: 'none', search: query})).onSuccess(function(response) {
                        context.drawingMaster =  response.data.data.data.data;                                 
                        context.inputTo.loading = false;   
                    }).onError(function(error) {
                        if (error.response.status == 404) {
                            context.drawingMaster = [];
                            context.inputTo.loading = false;
                        }
                    }).call();             
                })
		    },
		    dmAttachment(key) {
		    	let context = this;
                Api(context, drawingMaster.get(null, {per_page: 'none', search: context.equipmentQuantityInstall.drawing_number[key] })).onSuccess(function(response) {
       				context.dm_attachment[key] = response.data.data.data.data[0].file_name;
       				context.$forceUpdate()
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.dm_attachment = [];
                    }
                })
                .call()
		    },
		    // ACTION IN MCR
			addMpn() {
			  this.accordion.mpn.show = "show";

		      if (this.manufacturePartNumber.manufacture_code.length == 0) {
		        this.manufacturePartNumber.manufacture_code.push("");
		        this.manufacturePartNumber.mpn.push("");
		        this.manufacturePartNumber.manufacture_type.push("");
		        this.manufacturePartNumber.manufacture_note.push("");
		      } else if (this.manufacturePartNumber.manufacture_code.length > 0) {
		        if (
		          this.manufacturePartNumber.manufacture_code[
		            this.manufacturePartNumber.manufacture_code.length - 1
		          ] != "" &&
		          this.manufacturePartNumber.mpn[
		            this.manufacturePartNumber.mpn.length - 1
		          ] != "" &&
		          this.manufacturePartNumber.manufacture_type[
		            this.manufacturePartNumber.manufacture_type.length - 1
		          ] != "" &&
		          this.manufacturePartNumber.manufacture_note[
		            this.manufacturePartNumber.manufacture_note.length - 1
		          ] != ""
		        ) {
		          this.manufacturePartNumber.manufacture_code.push("");
		          this.manufacturePartNumber.mpn.push("");
		          this.manufacturePartNumber.manufacture_type.push("");
		          this.manufacturePartNumber.manufacture_note.push("");
		        } else {
		          this.$notify({
		            message: this.tt("please_complete_data", { title: this.tt("mpn") }),
		            type: "danger"
		          });
		        }
		      }
		    },
		    removeMpn(key) {
		    	this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        this.manufacturePartNumber.manufacture_code.splice(key, 1);
						this.manufacturePartNumber.mpn.splice(key, 1);
						this.manufacturePartNumber.manufacture_type.splice(key, 1);
						this.manufacturePartNumber.manufacture_note.splice(key, 1);
                    }
                })
		    },
		    addEqi() {
		      this.accordion.eqi.show = "show";

		      if (this.equipmentQuantityInstall.equipment_code.length == 0) {
		        this.equipmentQuantityInstall.equipment_code.push("");
		        this.equipmentQuantityInstall.qty_installed.push("");
		        this.equipmentQuantityInstall.drawing_number.push("");
		      } else if (this.equipmentQuantityInstall.equipment_code.length > 0) {
		        if (
		          this.equipmentQuantityInstall.equipment_code[
		            this.equipmentQuantityInstall.equipment_code.length - 1
		          ] != "" &&
		          this.equipmentQuantityInstall.qty_installed[
		            this.equipmentQuantityInstall.qty_installed.length - 1
		          ] != "" &&
		          this.equipmentQuantityInstall.drawing_number[
		            this.equipmentQuantityInstall.drawing_number.length - 1
		          ] != ""
		        ) {
		          this.equipmentQuantityInstall.equipment_code.push("");
		          this.equipmentQuantityInstall.qty_installed.push("");
		          this.equipmentQuantityInstall.drawing_number.push("");
		        } else {
		          this.$notify({
		            message: this.tt("please_complete_data", {title: this.tt("equipment_&_quantity_install")}),
		            type: "danger"
		          });
		        }
		      }
		    },
		    removeEqi(key) {
		    	this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        this.equipmentQuantityInstall.equipment_code.splice(key, 1);
						this.equipmentQuantityInstall.qty_installed.splice(key, 1);
						this.equipmentQuantityInstall.drawing_number.splice(key, 1);
                    }
                })
		    },
		    addAd() {
		      this.accordion.ad.show = "show";

		      if (this.attachmentDocument.file_attachment_name.length == 0) {
		        this.attachmentDocument.file_attachment_name.push("");
		        this.attachmentDocument.description_attachment.push("");
		      } else if (this.attachmentDocument.file_attachment_name.length > 0) {
		        if (
		          this.attachmentDocument.file_attachment_name[
		            this.attachmentDocument.file_attachment_name.length - 1
		          ] != "" &&
		          this.attachmentDocument.description_attachment[
		            this.attachmentDocument.description_attachment.length - 1
		          ] != "" 
		        ) {
		        	this.attachmentDocument.file_attachment_name.push("");
		        	this.attachmentDocument.description_attachment.push("");
		        } else {
		          this.$notify({
		            message: this.tt("please_complete_data", {title: this.tt("attachment_document")}),
		            type: "danger"
		          });
		        }
		      }
		    },
		    removeAd(key) {
		    	this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        this.attachmentDocument.file_attachment_name.splice(key, 1);
				        this.attachmentDocument.description_attachment.splice(key, 1);
                    }
                })
		    },
		    removePrevAd(id){
		    	this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, draftList.deleteMcrItemAttachment(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success'
                            });
                            context.getDetailMcrItem();
                        }).call();
                    }
                })
		    },
		    changeFileAttachmentName(files) {
		    	if (files[0].size > 2000000) {
		    		alert("MAX SIZE 2 MB")
		    		this.attachmentDocument == [];
		    	}else {
		    		this.attachmentDocument.file_attachment_name[
				        this.attachmentDocument.file_attachment_name.length - 1
				    ] = files[0];
		    	}
		    },
		    save(save) {
		      let context = this;
		      let formData = new FormData();

		      if (
		        context.input.mtype_code != "" &&
		        context.input.item_type_code != "" &&
		        context.input.plant_code != "" &&
		        context.input.fab_non_fab != "" &&
		        context.input.uom_code != "" &&
		        context.input.bom_non_bom != "" &&
		        context.input.item_name != "" &&
		        context.input.specification != "" &&
		        context.input.critical_non_critical != "" &&
		        // context.attachmentDocument.file_attachment_name.length > 0 &&
		        // context.attachmentDocument.file_attachment_name[
		        //   context.attachmentDocument.file_attachment_name.length - 1
		        // ] != "" &&
		        context.input.note != "") {

		      	// --------------------  BASIC DATA & SPECIFICATION ----------------------------
		        formData.append("mcr_code", context.mcr_code);
		        formData.append("mtype_code", context.input.mtype_code);
		        formData.append("item_type_code", context.input.item_type_code);
		        formData.append("plant_code", context.input.plant_code);
		        formData.append("fab_non_fab", context.input.fab_non_fab);
		        formData.append("uom_code", context.input.uom_code);
		        formData.append("bom_non_bom", context.input.bom_non_bom);
		        formData.append("item_name", context.input.item_name);
		        formData.append("specification", context.input.specification);
		        formData.append("critical_non_critical", context.input.critical_non_critical);
		        formData.append("note", context.input.note);

		        // insert for prc
		        formData.append("prc_mtype_code", context.input.mtype_code);
		        formData.append("prc_item_type_code", context.input.item_type_code);
		        formData.append("prc_fab_non_fab", context.input.fab_non_fab);
		        formData.append("prc_uom_code", context.input.uom_code);
		        formData.append("prc_bom_non_bom", context.input.bom_non_bom);
		        formData.append("prc_critical_non_critical", context.input.critical_non_critical);

		        // --------------------  FORM DATA MPN ----------------------------
		        if (context.manufacturePartNumber.manufacture_code.length) {
			        for (var i = 0; i < context.manufacturePartNumber.manufacture_code.length; i++) {
			          if (
			            context.manufacturePartNumber.manufacture_code[i] != "" &&
			            context.manufacturePartNumber.mpn[i] != "" &&
			            context.manufacturePartNumber.source_type[i] != "" &&
			            context.manufacturePartNumber.manufacture_type[i] != "" &&
			            context.manufacturePartNumber.manufacture_note[i] != ""
			          ) {
			            formData.append("manufacture_code[" + i + "]", context.manufacturePartNumber.manufacture_code[i]);
			            formData.append("mpn[" + i + "]", context.manufacturePartNumber.mpn[i]);
			            formData.append("source_type[" + i + "]", context.manufacturePartNumber.source_type[i]);
			            formData.append("manufacture_type[" + i + "]", context.manufacturePartNumber.manufacture_type[i]);
			            formData.append("manufacture_note[" + i + "]", context.manufacturePartNumber.manufacture_note[i]);

			            formData.append('prc_manufacture_code['+i+']', context.manufacturePartNumber.manufacture_code[i])
						formData.append('prc_mpn['+i+']', context.manufacturePartNumber.mpn[i])
						formData.append('prc_source_type['+i+']', context.manufacturePartNumber.source_type[i])
						formData.append('prc_manufacture_type['+i+']', context.manufacturePartNumber.manufacture_type[i])
						formData.append('prc_manufacture_note['+i+']', context.manufacturePartNumber.manufacture_note[i])
						formData.append('prc_manufacture_status['+i+']', 'new')
			          } else {
			            context.$notify({
			              message: this.tt("please_complete_data", {title: this.tt("mpn")}),
			              type: "danger"
			            });
			            return false;
			          }
			        }
			    }else {
                    formData.append("manufacture_code", '');
                    formData.append("mpn", '');
                    formData.append("source_type", '');
                    formData.append("manufacture_type", '');
                    formData.append("manufacture_note", '');
                }

		        // --------------------  FORM DATA EQUIPMENT & QUANTITY INSTALL ----------------------------
                if (context.equipmentQuantityInstall.equipment_code.length) {
			        for (var i = 0; i < context.equipmentQuantityInstall.equipment_code.length; i++) {
			          if (
			            context.equipmentQuantityInstall.equipment_code[i] != "" &&
			            context.equipmentQuantityInstall.qty_installed[i] != "" &&
			            context.equipmentQuantityInstall.drawing_number[i] != ""
			          ) {
			            formData.append("equipment_code[" + i + "]",context.equipmentQuantityInstall.equipment_code[i]);
			            formData.append("qty_installed[" + i + "]",context.equipmentQuantityInstall.qty_installed[i]);
			            formData.append("drawing_number[" + i + "]",context.equipmentQuantityInstall.drawing_number[i]);
			            formData.append("plant_equipment_code[" + i + "]",context.input.plant_code);

			            // insert for adopting to prc (cataloguer form)
			            formData.append('prc_plant_equipment['+i+']', context.input.plant_code)
			            formData.append("prc_equipment_code[" + i + "]",context.equipmentQuantityInstall.equipment_code[i]);
			            formData.append("prc_qty_installed[" + i + "]",context.equipmentQuantityInstall.qty_installed[i]);
			            formData.append("prc_drawing_number[" + i + "]",context.equipmentQuantityInstall.drawing_number[i]);
			            formData.append("prc_plant_equipment[" + i + "]",context.input.plant_code);
			            formData.append('prc_equipment_del_flag['+i+']', '')
						formData.append('prc_equipment_status['+i+']', 'new')
			          } else {
			            context.$notify({
			              message: this.tt("please_complete_data", {
			                title: this.tt("equipment_&_quantity_install")
			              }),
			              type: "danger"
			            });
			            return false;
			          }
			        }
			    } else {
                    if (context.input.bom_non_bom == 'BOM' || context.input.item_type_code == 'OEM') {
                        context.$notify({
                            message: this.tt("please_complete_data", { title: this.tt("equipment_&_quantity_install") }),                  
                            type: 'danger'
                        });
                        context.form.btnSave.onLoading = false;
                        return false;
                    }else{
                        formData.append("plant_equipment_code", '');
                        formData.append("equipment_code", '');
                        formData.append("qty_installed", '');
                        formData.append("drawing_number", '');
                    }
                }

                // --------------------  FORM DATA ATTACHMENT ----------------------------
                if (context.attachmentDocument.file_attachment_name.length || context.prevAttachmentDocument.length ) {
			        for (var i = 0; i < context.attachmentDocument.file_attachment_name.length; i++) {
			          	if (context.attachmentDocument.file_attachment_name[i] != "" && context.attachmentDocument.description_attachment[i] != "") {
				            formData.append("file_attachment_name[" + i + "]", context.attachmentDocument.file_attachment_name[i]);
				            formData.append("description_attachment[" + i + "]", context.attachmentDocument.description_attachment[i]);
			          	} else {
			          		context.$notify({
				              message: this.tt("please_complete_data", {
				                title: this.tt("attachment_document")
				              }),
				              type: "danger"
				            });
				            return false;
			          	}
			         }
			    }else {
                    context.$notify({
                        message: this.tt("please_complete_data", { title: this.tt("attachment_document") }),                  
                        type: 'danger'
                    });
                    context.form.btnSave.onLoading = false;
                    return false;
                }

		        let api = "";
		        if (save == "add") {
		          api = Api(context, draftList.createNewMcrItem(formData));
		        } else {
		          api = Api(context, draftList.updateMcrItem(formData, context.input.id));
		        }

		        context.form.btnSave.onLoading = true;

		        api.onSuccess(function(response) {
		            context.$notify({
						message: response.data.message,
						type: "success",
						verticalAlign: 'bottom', 
						horizontalAlign: 'left'
		            });
				})
				.onFinish(function() {
					context.form.btnSave.onLoading = false;
					context.$router.back();
				})
				.call();

		        // for (var pair of formData.entries()) {
          //           console.log(pair[0]+ ', ' + pair[1]); 
          //       }
		      } else {
		        context.$notify({
		          message: this.tt("please_complete_data", {title: this.tt("basic_data_&_specification")}),
		          type: "danger"
		        });
		      }
		    },
			toggleAccordion(show, type) {
				if (type == "bds") {
					if (show == "") {
					  this.accordion.bds.show = "show";
					} else {
					  this.accordion.bds.show = "";
					}
				} else if (type == "mpn") {
					if (show == "") {
					  this.accordion.mpn.show = "show";
					} else {
					  this.accordion.mpn.show = "";
					}
				} else if (type == "eqi") {
					if (show == "") {
					  this.accordion.eqi.show = "show";
					} else {
					  this.accordion.eqi.show = "";
					}
				} else if (type == "ad") {
					if (show == "") {
					  this.accordion.ad.show = "show";
					} else {
					  this.accordion.ad.show = "";
					}
				}
		    },
		    changeBom(){
		    	if (this.input.bom_non_bom == 'BOM' || this.input.item_type_code == 'OEM') {
		    		this.addEqi();
		    	}else{
		    		this.accordion.eqi.show = "";
		    		this.removeEqi();
		    	}
		    },
		}
	};
</script>