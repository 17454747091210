import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('ValuationClass?page=' + page, {params});
    }, 
    getByAccCatRef(material_type) {
        return Service().get('ValuationClass/get-by-acc-cat-ref/'+material_type);
    }, 
    show(id) {
        return Service().get('ValuationClass/show/'+id);
    },    
    create(params) {
        return Service().post('ValuationClass/create', params);
    },
    update(id, params) {
        return Service().post('ValuationClass/update/'+id, params);
    },
    delete(id) {
        return Service().post('ValuationClass/delete/'+id);
    }
}