<template>
        <div v-if="requestCalls > 0">
            <skeleton-loading></skeleton-loading>
        </div>
        <div v-else>
            <div class="row">
                <div class="col-md-12">
                    <label class="form-control-label">{{ tt('material_number') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('material_number')" rules="required">
                        <el-select v-model="inputDraftListA2.material_number" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 3})" :remote-method="getMaterialNumber" :loading="inputTo.loading" @change="changeMaterialNumberA2" :disabled="form.a2.disabled">
                            <el-option label="" value=""></el-option>
                            <el-option v-for="mn in materialNumber" :key="mn.material_number" :label="mn.material_number +' - '+ mn.short_description" :value="mn.material_number"></el-option>
                        </el-select>  
                    </base-input>
                    <input type="hidden" v-model="inputDraftListA2.client_plant_level" value="Client">
                </div>
            </div>

            <!-- CLIENT LEVEL -->

            <div v-if="inputDraftListA2.client_plant_level == 'Client'">
                <div id="accordionClA2">
                    <div class="card z-depth-0 bordered">
                        <div class="card-header bg-secondary border" id="headingOneClA2">
                            <h4 class="pt-2 pl-2 pb-2 pr-2 mb--1">
                                <div class="row">
                                    <div class="col-md-6">
                                        <span @click="toggleAccordion(accordion.a2.cl.show, 'clA2')">{{tt('client_level')}}</span>
                                    </div>
                                </div>
                            </h4>
                        </div>
                        <div id="collapseClA2" :class="'collapse '+accordion.a2.cl.show">
                            <div class="card-body border">
                                <div class="table-responsive">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label class="form-control-label">{{ tt('item_type') }}</label><span class="text-danger">*</span></label>
                                            <base-input :name="tt('item_type')" rules="required">
                                                <el-select :disabled="form.a2.disabled" class="select-danger" v-model="inputDraftListA2.item_type_code" :placeholder="tt('choose_item_type')">
                                                    <el-option class="select-danger" :value="it" :label="it" :key="it" v-for="it in  itemType"></el-option>
                                                </el-select>
                                            </base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">{{ tt('critical_non_critical') }}</label><span class="text-danger">*</span></label>
                                            <base-input :name="tt('critical_non_critical')" rules="required">
                                                <el-select :disabled="form.a2.disabled" class="select-danger" v-model="inputDraftListA2.critical_non_critical" :placeholder="tt('choose_critical_non_critical')">
                                                    <el-option class="select-danger" :value="cnc" :label="cnc" :key="cnc" v-for="cnc in  criticalNonCritical"></el-option>
                                                </el-select>
                                            </base-input>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label class="form-control-label">{{ tt('fabric_or_non_fabrication') }}</label><span class="text-danger">*</span></label>
                                            <base-input :name="tt('fabric_or_non_fabrication')" rules="required">
                                                <el-select :disabled="form.a2.disabled" class="select-danger" v-model="inputDraftListA2.fab_non_fab" :placeholder="tt('choose_fabric_or_non_fabrication')">
                                                    <el-option class="select-danger" :value="fnf" :label="fnf" :key="fnf" v-for="fnf in  fabNonFab"></el-option>
                                                </el-select>
                                            </base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">{{ tt('bom_or_non_bom') }}</label><span class="text-danger">*</span></label>
                                            <base-input :name="tt('bom_or_non_bom')" rules="required">
                                                <el-select :disabled="form.a2.disabled" class="select-danger" v-model="inputDraftListA2.bom_non_bom" :placeholder="tt('choose_bom_or_non_bom')">
                                                    <el-option class="select-danger" :value="bnb" :label="bnb" :key="bnb" v-for="bnb in  bomNonBom"></el-option>
                                                </el-select>
                                            </base-input>
                                        </div>
                                    </div>
                                    <label class="form-control-label">{{ tt('item_name') }}</label><span class="text-danger">*</span></label>
                                    <base-input :disabled="form.a2.disabled" :name="tt('item_name')" :placeholder="tt('item_name')" v-model="inputDraftListA2.item_name" rules="required"></base-input>

                                    <label class="form-control-label mt-4">{{ tt('specification') }}</label><span class="text-danger">*</span></label>
                                    <base-input :name="tt('specification')" rules="required">
                                        <textarea :disabled="form.a2.disabled" class="form-control" rows="5" v-model="inputDraftListA2.specification"></textarea>
                                    </base-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- MPN -->

                <div id="accordionMpnA2">
                    <div class="card z-depth-0 bordered">
                        <div class="card-header bg-secondary border" id="headingOneMpnA2">
                            <h4 class="pt-2 pl-2 pb-2 pr-2 mb--1">
                                <div class="row">
                                    <div class="col-md-6">
                                        <span @click="toggleAccordion(accordion.a2.mpn.show, 'mpnA2')">{{tt('manufacturer_part_number')}}</span>
                                    </div>
                                    <div class="col-md-6 text-right">
                                        <base-button v-if="!form.a2.disabled" size="sm" type="default" @click="addMpnA2">{{ tt('add_new') }}</base-button>
                                    </div>
                                </div>
                            </h4>
                        </div>
                        <div id="collapseMpnA2" :class="'collapse '+accordion.a2.mpn.show">
                            <div class="card-body border">
                                <div class="table-responsive">
                                    <table class="table align-items-center table-flush table-bordered">
                                        <thead class="card-header bg-primary">
                                            <tr>
                                                <th min-width="500" class="text-white">{{tt('manufacturer_code')}}</th>
                                                <th class="text-white">{{ tt("manufacture_ref") }}</th>
                                                <th class="text-white">{{ tt("source_type") }}</th>
                                                <th class="text-white">{{tt('type')}}</th>
                                                <th class="text-white">{{tt('note')}}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(mpn, key) in manufacturePartNumberA2.manufacture_code" v-bind:key="mpn.manufacture_code">
                                                <td>
                                                    <el-select v-model="manufacturePartNumberA2.manufacture_code[key]" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 4})" :remote-method="getManufacture" :loading="inputTo.loading" :disabled="form.a2.disabled">
                                                        <el-option label="" value=""></el-option>
                                                        <el-option v-for="mn in manufacture" :key="mn.manufacture_code" :label="mn.manufacture_code +' - '+ mn.manufacture_name" :value="mn.manufacture_code"></el-option>
                                                    </el-select>
                                                </td>
                                                <td>
                                                    <input :disabled="form.a2.disabled" class="form-control" :name="tt('mpn')" :placeholder="tt('mpn')" v-model="manufacturePartNumberA2.mpn[key]" rules="required">
                                                </td>
                                                <td>
                                                    <select :disabled="form.a2.disabled" class="form-control" v-model="manufacturePartNumberA2.source_type[key]">
                                                        <option value="">Choose Type</option>
                                                        <option :value="st.code" v-for="st in sourceType" v-bind:key="st.id">{{ st.code +' - '+ st.description }}</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <select :disabled="form.a2.disabled" class="form-control" v-model="manufacturePartNumberA2.manufacture_type[key]">
                                                            <option value="">Choose Type</option>
                                                        <option :value="mt.code" v-for="mt in manrefType" v-bind:key="mt.id">{{mt.description}}</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <input :disabled="form.a2.disabled" class="form-control" :name="tt('note')" :placeholder="tt('note')" v-model="manufacturePartNumberA2.manufacture_note[key]" rules="required">
                                                </td>
                                                <td>
                                                  <i v-if="!form.a2.disabled" class="fa fa-trash text-danger" @click="removeMpnA2(key)"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- EQUIPMENT & QUANTITY INSTALL -->

                <div id="accordionEqiA2">
                    <div class="card z-depth-0 bordered">
                        <div class="card-header bg-secondary border" id="headingOneEqiA2">
                            <h4 class="pt-2 pl-2 pb-2 pr-2 mb--1">
                                <div class="row">
                                    <div class="col-md-6">
                                        <span @click="toggleAccordion(accordion.a2.eqi.show, 'eqiA2')">{{tt('equipment_&_quantity_install')}}</span>
                                        <span class="text-danger" v-if= "this.inputDraftListA2.bom_non_bom == 'BOM' || this.inputDraftListA2.item_type_code == 'OEM'"> *</span>
                                    </div>
                                    <div class="col-md-6 text-right">
                                        <base-button v-if="!form.a2.disabled" size="sm" type="default" @click="addEqiA2">{{ tt('add_new') }}</base-button>
                                    </div>
                                </div>
                            </h4>
                        </div>
                        <div id="collapseEqiA2" :class="'collapse '+accordion.a2.eqi.show">
                            <div class="card-body border">
                                <div class="table-responsive">
                                    <table class="table align-items-center table-flush table-bordered">
                                        <thead class="card-header bg-primary">
                                            <tr>
                                                <th class="text-white">{{tt('plant')}}</th>
                                                <th min-width="500" class="text-white">{{tt('equipment_code')}}</th>
                                                <th class="text-white">{{tt('quantity_install')}}</th>
                                                <th class="text-white">{{tt('drawing_number')}}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(eqi, key) in equipmentQuantityInstallA2.equipment_code" v-bind:key="eqi.equipment_code">
                                                <td>
                                                    <el-select v-model="equipmentQuantityInstallA2.plant[key]" filterable remote reserve-keyword :placeholder="tt('choose_plant')" :remote-method="getPlantCode" :loading="inputTo.loading" :disabled="form.a2.disabled">
                                                        <el-option label="" value=""></el-option>
                                                        <el-option v-for="pc in plantCode" :key="pc.plant_code" :label="pc.plant_code +' - '+ pc.description" :value="pc.plant_code"></el-option>
                                                    </el-select>
                                                </td>
                                                <td>
                                                    <el-select v-model="equipmentQuantityInstallA2.equipment_code[key]" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 4})" :remote-method="getEquipmentCode" :loading="inputTo.loading" :disabled="form.a2.disabled">
                                                        <el-option label="" value=""></el-option>
                                                        <el-option v-for="ec in equipmentCode" :key="ec.equipment_code" :label="ec.equipment_code +' - '+ ec.equipment_name" :value="ec.equipment_code"></el-option>
                                                    </el-select>
                                                </td>
                                                <td><input :disabled="form.a2.disabled" class="form-control" :name="tt('qty_install')" :placeholder="tt('qty_install')" v-model="equipmentQuantityInstallA2.qty_installed[key]" rules="required"></td>
                                                <td>
                                                    <el-select style="width: 100%;" size="small" v-model="equipmentQuantityInstallA2.drawing_number[key]" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="getDrawingMaster" :loading="inputTo.loading" :disabled="form.a2.disabled">
                                                        <el-option label="" value=""></el-option>
                                                        <el-option v-for="dm in drawingMaster" :key="dm.drawing_number" :label="dm.drawing_number +' - '+ dm.drawing_name" :value="dm.drawing_number"></el-option>
                                                    </el-select>
                                                </td>
                                                <td>
                                                    <i v-if="!form.a2.disabled" class="fa fa-trash text-danger" @click="removeEqiA2(key)"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ATTACHMENT -->

            <div id="accordionAd">
                <div class="card z-depth-0 bordered">
                  <div class="card-header bg-secondary border" id="headingOneAd">
                    <div class="row">
                        <div class="col-md-6">
                            <h5>
                              <a
                                href="javascript:void(0)"
                                @click="toggleAccordion(accordion.a2.ad.show, 'ad')"
                                >{{ tt("attachment_document") }}
                                <span class="text-danger">*</span></a
                              >
                            </h5>
                        </div>
                      <div class="col-md-6 text-right">
                        <base-button v-if="!form.a2.disabled" size="sm" type="default" @click="addAd">{{ tt("add_new") }}</base-button>
                      </div>
                    </div>
                  </div>
                  <div id="collapseAd" :class="'collapse ' + accordion.a2.ad.show">
                    <div class="card-body border">
                      <div class="table-responsive">
                        <table class="table align-items-center table-flush table-bordered">
                          <thead class="card-header bg-primary">
                            <tr>
                              <th class="text-white">{{ tt("file_name") }}</th>
                              <th class="text-white">{{ tt("description") }}</th>
                              <th width="1"></th>
                            </tr>
                          </thead>
                          <tbody>

                            <!-- PREVIEW KETIKA EDIT -->
                            <tr v-for="(att, index) in prevAttachmentDocument">
                                <td>
                                    <a v-if="formType == 'add'" :href="apiUrl+'smartcat_attachment/'+att.file_attachment_name+'.'+att.file_type" target="_blank">{{att.file_attachment_name+'.'+att.file_type}}</a>
                                    <a v-else :href="apiUrl+'smartcat_attachment/'+att.file_attachment_name" target="_blank">{{att.file_attachment_name}}</a>
                                </td>
                                <td>
                                    <p>{{att.description_attachment}}</p>
                                </td>
                                <td>
                                    <a href="javascript:void(0)" v-if="!form.a2.disabled" @click="removePrevAd(att.id)"><i class="fa fa-trash text-danger"></i></a>
                                </td>
                            </tr>

                            <!-- INPUTAN ATTACHMENT -->
                            <tr v-for="(ad, key) in attachmentDocument.file_attachment_name" v-bind:key="ad.file_name">
                              <td>
                                <file-input
                                  :disabled="form.disabled"
                                  @change="changeFileAttachmentName"
                                  :initialLabel="ad.file_attachment_name"
                                  :ref="'file' + key"
                                  accept="application/pdf"
                                ></file-input>
                              </td>
                              <td>
                                <input :disabled="form.disabled" class="form-control" :name="tt('description')" :placeholder="tt('description')" v-model="attachmentDocument.description_attachment[key]" rules="required">
                              </td>
                              <td>
                                <a href="javascript:void(0)" v-if="!form.a2.disabled" @click="removeAd(key)"><i class="fa fa-trash text-danger"></i></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            
            <label class="form-control-label mt-5">{{ tt('reason') }} <span class="text-danger">*</span></label>
            <base-input :disabled="form.a2.disabled" :name="tt('reason')" :placeholder="tt('reason')" v-model="inputDraftListA2.reason" rules="required"></base-input>

            <label class="form-control-label">{{ tt('note') }}</label>
            <base-input :name="tt('note')" rules="required">
                <textarea :disabled="form.a2.disabled" class="form-control" rows="5" v-model="inputDraftListA2.note"></textarea>
            </base-input>

            <div class="float-right">
                <base-button
                  type="primary"
                  @click="save('add')"
                  v-if="formType == 'add'"
                  :disabled="form.btnSave.onLoading">
                  <span v-if="form.btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
                  <span v-else>{{ tt("add") }}</span>
                </base-button
                >
                <base-button
                  type="primary"
                  @click="save('update')"
                  v-else-if="formType == 'edit'"
                  :disabled="form.btnSave.onLoading">
                  <span v-if="form.btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
                  <span v-else>{{ tt("edit") }}</span>
                </base-button>
            </div>
        </div>
</template>
<script>
    import Api from '@/helpers/api';
    import draftList from '@/services/material/draftList.service';
    import materialType from '@/services/master/materialType.service';
    import plantCode from '@/services/master/plantCode.service';
    import materialNumber from '@/services/master/materialNumber.service';
    import manufacture from '@/services/master/manufacture.service';
    import manrefType from '@/services/master/manrefType.service';
    import equipmentCode from '@/services/master/equipmentCode.service';
    import abcIndicator from '@/services/master/abcIndicator.service';
    import mrpTypes from '@/services/master/mrpTypes.service';
    import mrpController from '@/services/master/mrpController.service';
    import drawingMaster from '@/services/dictionary/drawingMaster.service';
    import endUserSearchMaterial from '@/services/endUserSearch/endUserSearchMaterial.service';
    import sourceType from "@/services/master/sourceType.service";
    import baseApiUrl from '@/configs/config';

    export default {        
        data() {
            return {  
                requestCalls: 0,
                form: {
                    a2: {
                        show: false,
                        disabled: false,
                        type: 'add',
                    },
                    btnSave: {
                        onLoading: false
                    }
                },
                inputDraftListA2: {
                    material_number: '',
                    client_plant_level: '',
                    item_type_code: '',
                    critical_non_critical: '',
                    bom_non_bom: '',
                    fab_non_fab: '',
                    item_name: '',
                    specification: '',
                    plant_code: '',
                    abc_indicator_code: '',
                    mrp_type_code: '',
                    mrp_type_code_view: '',
                    mrp_controller_code: '',
                    mrp_controller_code_view: '',
                    min_stock: '',
                    max_stock: '',
                    old_min_stock: '',
                    old_max_stock: '',
                    reason: '',
                    note: ''
                },
                materialNumber: [],
                manufacture: [],
                manrefType: {},
                sourceType: {},
                equipmentCode: [],
                abcIndicator: {},
                mrpTypes: [],
                mrpController: [],
                drawingMaster: {},
                itemType: {},
                fabNonFab: {},
                bomNonBom: {},
                criticalNonCritical: {},
                plantLevel: [],
                plantCode: [],
                equipmentCode: {},
                manufacture: {},
                mrpTypes: {},
                mrpController: {},
                accordion: {
                    a2: {
                        cl: {
                            show: 'show'
                        },
                        pl: {
                            show: 'show'
                        },
                        mpn: {
                            show: 'show'
                        },
                        eqi: {
                            show: 'show'
                        },
                        ad: {
                            show: "show"
                        }
                    },  
                },
                inputTo: {
                    loading: false,
                    loadTimeout: null
                },
                manufacturePartNumberA2: {
                    manufacture_code: [],
                    manufacture_code_view: [],
                    mpn: [],
                    source_type: [],
                    manufacture_type: [],
                    manufacture_note: []
                },
                equipmentQuantityInstallA2: {
                    plant: [],
                    plant_view: [],
                    equipment_code: [],
                    equipment_code_view: [],
                    qty_installed: [],
                    drawing_number: [],
                },
                attachmentDocument: {
                    file_attachment_name: [],
                    description_attachment: []
                },
                prevAttachmentDocument: [],
                apiUrl: baseApiUrl.storageUrl,

                mcr_code: this.$route.params.mcr_code,
                mcrItemCode: this.$route.params.mcr_item_code,
                formType: this.$route.params.type,
            }
        },
        mounted() {
            if (this.formType == 'edit' || this.formType == 'detail' || this.formType == 'approval' || this.formType == 'approval-detail') {
                this.getDetailMcrItem();
                this.checkFormType();
            }
            this.getParameterMcr();
            this.getPlantCode();
            this.getSourceType();
            this.getManrefType();
            // this.getAbcIndicator();
            this.inputDraftListA2.client_plant_level = 'Client';
        },
        methods: {
            checkFormType() {
                switch(this.formType) {
                    case 'edit':
                        this.form.a2.disabled = false;
                        break;
                    case 'detail':
                        this.form.a2.disabled = true;
                        break;
                    case 'approval':
                        this.form.a2.disabled = true;
                        break;
                    case 'approval-detail':
                        this.form.a2.disabled = true;
                        break;
                }
            },
            getDetailMcrItem() {
                this.requestCalls++;
                let context = this; 
                              
                Api(context, draftList.getDetailMcrItem(this.mcrItemCode)).onSuccess(function(response) {    
                        context.inputDraftListA2 = response.data.data[0];
                        context.mrpTypes[0]      = response.data.data[0];
                        context.mrpController[0] = response.data.data[0];
                        context.materialNumber[0] = {
                            material_number: response.data.data[0].material_number,
                            short_description: response.data.data[0].short_description,
                        };
                        context.plantLevel[0] = {
                            plant_code: response.data.data[0].plant_code,
                            description: response.data.data[0].description,
                        };
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.inputDraftListA2 = [];
                        }
                    }).call()

                    // MPN
                    Api(context, draftList.getMcrItemMpn(this.mcrItemCode)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.manufacture[i]                              = response.data.data[i];
                            context.manufacturePartNumberA2.manufacture_code[i] = response.data.data[i].manufacture_code;
                            context.manufacturePartNumberA2.mpn[i]              = response.data.data[i].mpn;
                            context.manufacturePartNumberA2.source_type[i]      = response.data.data[i].source_type;
                            context.manufacturePartNumberA2.manufacture_type[i] = response.data.data[i].manufacture_type;
                            context.manufacturePartNumberA2.manufacture_note[i] = response.data.data[i].manufacture_note;
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.manufacturePartNumberA2.manufacture_code = [];
                            context.manufacturePartNumberA2.mpn              = [];
                            context.manufacturePartNumberA2.manufacture_type = [];
                            context.manufacturePartNumberA2.manufacture_note = [];
                        }
                    }).call()

                    // EQUIPMENT & QUANITY INSTALL
                    Api(context, draftList.getMcrItemEquipment(this.mcrItemCode)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.equipmentCode[i]                             = response.data.data[i];
                            context.equipmentQuantityInstallA2.plant[i]          = response.data.data[i].plant_code;
                            context.equipmentQuantityInstallA2.plant_view[i]     = response.data.data[i].plant_code +' - '+ response.data.data[i].description;
                            context.equipmentQuantityInstallA2.equipment_code[i] = response.data.data[i].equipment_code;
                            context.equipmentQuantityInstallA2.qty_installed[i]  = response.data.data[i].qty_installed;
                            context.equipmentQuantityInstallA2.drawing_number[i] = response.data.data[i].drawing_number;
                            context.drawingMaster[i] = {
                                drawing_number:response.data.data[i].drawing_number,
                                drawing_name:response.data.data[i].drawing_name,
                            };
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.equipmentQuantityInstallA2.plant = [];
                            context.equipmentQuantityInstallA2.equipment_code = [];
                            context.equipmentQuantityInstallA2.qty_installed = [];
                            context.equipmentQuantityInstallA2.drawing_number = [];
                        }
                    }).call()

                    // ATTACHMENT
                    Api(context, draftList.getMcrItemAttachment(this.mcrItemCode))
                    .onSuccess(function(response) {
                        context.prevAttachmentDocument = response.data.data;
                    })
                    .onError(function(error) {
                      if (error.response.status == 404) {
                        context.prevAttachmentDocument = [];
                      }
                    }).onFinish(function(){
                        context.requestCalls--;
                    }).call()
            },
            changeMaterialNumberA2() {         
                let context = this;
                Api(context, endUserSearchMaterial.partCharValueEndUser({material_number: context.inputDraftListA2.material_number})).onSuccess(function(response) {
                    context.inputDraftListA2.item_type_code        = response.data.data.part_master[0].item_type_code;
                    context.inputDraftListA2.critical_non_critical = response.data.data.part_master[0].critical_non_critical;
                    context.inputDraftListA2.bom_non_bom           = response.data.data.part_master[0].bom_non_bom;
                    context.inputDraftListA2.fab_non_fab           = response.data.data.part_master[0].fab_non_fab;
                    context.inputDraftListA2.item_name             = response.data.data.part_master[0].item_name;

                    // MPN
                    for (var i=0; i<response.data.data.part_manufacture_ref.length; i++) {
                        context.manufacture[i]                              = response.data.data.part_manufacture_ref[i];
                        context.manufacturePartNumberA2.manufacture_code[i] = response.data.data.part_manufacture_ref[i].manufacture_code;
                        context.manufacturePartNumberA2.mpn[i]              = response.data.data.part_manufacture_ref[i].manufacture_ref;
                        context.manufacturePartNumberA2.source_type[i]      = response.data.data.part_manufacture_ref[i].source_type_code;
                        context.manufacturePartNumberA2.manufacture_type[i] = response.data.data.part_manufacture_ref[i].type;
                        context.manufacturePartNumberA2.manufacture_note[i] = response.data.data.part_manufacture_ref[i].note;
                    }

                    // EQUIPMENT & QUANTITY INSTALL
                    for (var i=0; i<response.data.data.part_equipment_code.length; i++) {
                        context.equipmentCode[i]                             = response.data.data.part_equipment_code[i];
                        context.equipmentQuantityInstallA2.plant[i]          = response.data.data.part_equipment_code[i].plant_code;
                        context.equipmentQuantityInstallA2.plant_view[i]     = response.data.data.part_equipment_code[i].plant_code +' - '+ response.data.data.part_equipment_code[i].description;
                        context.equipmentQuantityInstallA2.equipment_code[i] = response.data.data.part_equipment_code[i].equipment_code;
                        context.equipmentQuantityInstallA2.qty_installed[i]  = response.data.data.part_equipment_code[i].qty_installed;
                        context.equipmentQuantityInstallA2.drawing_number[i] = response.data.data.part_equipment_code[i].drawing_number;
                        context.drawingMaster[i] = {
                            drawing_number:response.data.data.part_equipment_code[i].drawing_number,
                            drawing_name:response.data.data.part_equipment_code[i].drawing_name,
                        };
                    }

                    // ATTACHMENT
                    // context.attachmentDocument.file_attachment_name = response.data.data.part_attachment;
                    // context.attachmentDocument.description_attachment = '';
                    // context.prevAttachmentDocument = response.data.data.part_attachment;
                    context.plantLevel = response.data.data.part_plant;

                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.manufacturePartNumberA2.manufacture_code = [];
                        context.manufacturePartNumberA2.mpn = [];
                        context.manufacturePartNumberA2.manufacture_type = [];
                        context.manufacturePartNumberA2.manufacture_note = [];

                        context.equipmentQuantityInstallA2.plant = [];
                        context.equipmentQuantityInstallA2.equipment_code = [];
                        context.equipmentQuantityInstallA2.qty_installed = [];
                        context.equipmentQuantityInstallA2.drawing_number = [];
                    }
                })
                .call()
            },
            getMaterialPlant() {
                let context = this;               
                Api(context, endUserSearchMaterial.getMaterialPlant({material_number: context.inputDraftListA2.material_number, plant_code: context.inputDraftListA2.plant_code})).onSuccess(function(response) {  
                        context.mrpTypes[0]                          = response.data.data.part_mrp[0];
                        context.mrpController[0]                     = response.data.data.part_mrp[0];
                        context.inputDraftListA2.abc_indicator_code  = response.data.data.part_mrp[0].abc_indicator_code;
                        context.inputDraftListA2.mrp_type_code       = response.data.data.part_mrp[0].mrp_type_code;
                        context.inputDraftListA2.mrp_controller_code = response.data.data.part_mrp[0].mrp_controller_code;
                        context.inputDraftListA2.old_max_stock       = response.data.data.part_mrp[0].max;
                        context.inputDraftListA2.old_min_stock       = response.data.data.part_mrp[0].min;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.plantCode = [];
                    }
                })
                .call()
            },
            getParameterMcr() {
                this.requestCalls++;
                let context = this;

                Api(context, draftList.getParameterMcr(this.mcrItemCode))
                .onSuccess(function(response) {
                  context.itemType            = response.data.data.item_type;
                  context.fabNonFab           = response.data.data.fab_non_fab;
                  context.bomNonBom           = response.data.data.bom_non_bom;
                  context.criticalNonCritical = response.data.data.critical_non_critical;
                })
                .onError(function(error) {
                  if (error.response.status == 404) {
                    context.input = [];
                  }
                }).onFinish(function(){
                    context.requestCalls--;
                }).call()
            },
            getPlantCode(query) {
                clearTimeout(this.inputTo.loadTimeout);             
                let context = this;   
                this.inputTo.loadTimeout = setTimeout(() => {
                    this.inputTo.loading = true;
                    Api(context, plantCode.companyPlant({search: query})).onSuccess(function(response) {
                        context.plantCode =  response.data.data;                                    
                        context.inputTo.loading = false;   
                    }).onError(function(error) {
                        if (error.response.status == 404) {
                            context.plantCode = [];
                            context.inputTo.loading = false;
                        }
                    }).call();             
                }) 
            },
            getMaterialNumber(query) {                        
                if (query.length > 2) {
                    clearTimeout(this.inputTo.loadTimeout);             
                    let context = this;   
                    this.inputTo.loadTimeout = setTimeout(() => {
                        this.inputTo.loading = true;
                        Api(context,  materialNumber.get({search: query})).onSuccess(function(response) {
                            context.materialNumber = response.data.data;                        
                            context.inputTo.loading = false;                     
                        }).onError(function(error) {
                            context.materialNumber = [];
                            context.inputTo.loading = false;
                        }).call();             
                    })     
                }           
            },
            getManufacture(query) {
                if (query.length > 3) {
                    clearTimeout(this.inputTo.loadTimeout);             
                    let context = this;   
                    this.inputTo.loadTimeout = setTimeout(() => {
                        this.inputTo.loading = true;
                        Api(context, manufacture.get({per_page: 'none', search: query})).onSuccess(function(response) {
                            context.manufacture =  response.data.data.data.data;                                    
                            context.inputTo.loading = false;   
                        }).onError(function(error) {
                            if (error.response.status == 404) {
                                context.manufacture = [];
                                context.inputTo.loading = false;
                            }
                        }).call();             
                    }) 
                }
            },
            getManrefType() {
                this.requestCalls++;
                let context = this;  

                Api(context, manrefType.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.manrefType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.manrefType = [];
                    }
                }).onFinish(function(){
                    context.requestCalls--;
                }).call()
            },
            getSourceType() {
                this.requestCalls++;
                let context = this;

                Api(context, sourceType.get(null, { per_page: "none" }))
                .onSuccess(function(response) {
                  context.sourceType = response.data.data.data.data;
                })
                .onError(function(error) {
                  if (error.response.status == 404) {
                    context.sourceType = [];
                  }
                }).onFinish(function(){
                    context.requestCalls--;
                }).call()
            },
            getEquipmentCode(query) {
                if (query.length > 3) {
                    clearTimeout(this.inputTo.loadTimeout);             
                    let context = this;   
                    this.inputTo.loadTimeout = setTimeout(() => {
                        this.inputTo.loading = true;
                        Api(context, equipmentCode.get(null, {per_page: 'none', search: query})).onSuccess(function(response) {
                            context.equipmentCode =  response.data.data.data.data;                                         
                            context.inputTo.loading = false;   
                        }).onError(function(error) {
                            if (error.response.status == 404) {
                                context.equipmentCode = [];
                                context.inputTo.loading = false;
                            }
                        }).call();             
                    }) 
                }
            },
            getAbcIndicator() {
                let context = this;               
                Api(context, abcIndicator.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.abcIndicator = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.abcIndicator = [];
                    }
                })
                .call()
            },
            getMrpTypes(query) {
                clearTimeout(this.inputTo.loadTimeout);             
                let context = this;   
                this.inputTo.loadTimeout = setTimeout(() => {
                    this.inputTo.loading = true;
                    Api(context, mrpTypes.get(null, {per_page: 'none', search: query})).onSuccess(function(response) {
                        context.mrpTypes =  response.data.data.data.data;                                     
                        context.inputTo.loading = false;   
                    }).onError(function(error) {
                        if (error.response.status == 404) {
                            context.mrpTypes = [];
                            context.inputTo.loading = false;
                        }
                    }).call();             
                }) 
            },
            getMrpController(query) {
                clearTimeout(this.inputTo.loadTimeout);             
                let context = this;   
                this.inputTo.loadTimeout = setTimeout(() => {
                    this.inputTo.loading = true;
                    Api(context, mrpController.get(null, {per_page: 'none', search: query})).onSuccess(function(response) {
                        context.mrpController =  response.data.data.data.data;                                 
                        context.inputTo.loading = false;   
                    }).onError(function(error) {
                        if (error.response.status == 404) {
                            context.mrpController = [];
                            context.inputTo.loading = false;
                        }
                    }).call();             
                })
            },
            getDrawingMaster(query) {
                clearTimeout(this.inputTo.loadTimeout);             
                let context = this;   
                this.inputTo.loadTimeout = setTimeout(() => {
                    this.inputTo.loading = true;
                    Api(context, drawingMaster.get(null, {per_page: 'none', search: query})).onSuccess(function(response) {
                        context.drawingMaster =  response.data.data.data.data;                                    
                        context.inputTo.loading = false;   
                    }).onError(function(error) {
                        if (error.response.status == 404) {
                            context.drawingMaster = [];
                            context.inputTo.loading = false;
                        }
                    }).call();             
                })
            },
            handleTableAction(command) {
                this.formTracking.show = false;
                switch (command.action) {
                    case 'detail':
                            this.detail(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            changeClientPlantLevelA2() {
                // if (this.inputDraftListA2.client_plant_level == 'Client') {
                //     this.condition.a2.client_level.show = true;
                // } else {
                //     this.condition.a2.client_level.show = false;
                // }
            },
            toggleAccordion(show, type) {
                if (type == 'clA2') {
                    if (show == '') {
                        this.accordion.a2.cl.show = 'show'
                    } else {
                        this.accordion.a2.cl.show = ''
                    }
                } else if (type == 'plA2') {
                    if (show == '') {
                        this.accordion.a2.pl.show = 'show'
                    } else {
                        this.accordion.a2.pl.show = ''
                    }
                } else if (type == 'mpnA2') {
                    if (show == '') {
                        this.accordion.a2.mpn.show = 'show'
                    } else {
                        this.accordion.a2.mpn.show = ''
                    }
                } else if (type == 'eqiA2') {
                    if (show == '') {
                        this.accordion.a2.eqi.show = 'show'
                    } else {
                        this.accordion.a2.eqi.show = ''
                    }
                } else if (type == "ad") {
                    if (show == "") {
                      this.accordion.ad.show = "show";
                    } else {
                      this.accordion.ad.show = "";
                    }
                }
            },
            save(save) {
                let context = this;    
                let formData = new FormData();
                context.form.btnSave.onLoading = true;

                if (context.inputDraftListA2.material_number != '' &&
                    context.inputDraftListA2.client_plant_level != '' &&
                    context.inputDraftListA2.reason != '') {        
                   
                    formData.append("mcr_code", context.mcr_code);
                    formData.append("material_number", context.inputDraftListA2.material_number);
                    formData.append("client_plant_level", context.inputDraftListA2.client_plant_level);
                    formData.append("reason", context.inputDraftListA2.reason);
                    formData.append("note", context.inputDraftListA2.note);

                    // --------------------  FORM DATA CLIENT ----------------------------
                    if (context.inputDraftListA2.item_type_code != null) {
                        formData.append("item_type_code", context.inputDraftListA2.item_type_code);
                    }else{
                        context.mandatoryClient('item_type');
                        return false;
                    }
                    if (context.inputDraftListA2.critical_non_critical != null) {
                        formData.append("critical_non_critical", context.inputDraftListA2.critical_non_critical);
                    }else{
                        context.mandatoryClient('critical_non_critical');
                        return false;
                    }
                    if (context.inputDraftListA2.fab_non_fab != null) {
                        formData.append("fab_non_fab", context.inputDraftListA2.fab_non_fab);
                    }else{
                        context.mandatoryClient('fab_non_fab');
                        return false;
                    }
                    if (context.inputDraftListA2.bom_non_bom != null) {
                        formData.append("bom_non_bom", context.inputDraftListA2.bom_non_bom);
                    }else{
                        context.mandatoryClient('bom_non_bom');
                        return false;
                    }
                    if (context.inputDraftListA2.item_name != '') {
                        formData.append("item_name", context.inputDraftListA2.item_name);
                    }else{
                        context.mandatoryClient('item_name');
                        return false;
                    }
                    if (context.inputDraftListA2.specification != '' ) {
                        formData.append("specification", context.inputDraftListA2.specification);
                    }else{
                        context.mandatoryClient('specification');
                        return false;
                    }
                    
                    // --------------------  FORM DATA MPN ----------------------------
                    if (context.manufacturePartNumberA2.manufacture_code.length) {
                        for (var i=0; i<context.manufacturePartNumberA2.manufacture_code.length; i++) {
                            if (context.manufacturePartNumberA2.manufacture_code[i] != '' &&
                                context.manufacturePartNumberA2.mpn[i] != '' &&
                                context.manufacturePartNumberA2.source_type[i] != '' &&
                                context.manufacturePartNumberA2.manufacture_type[i] != '' &&
                                context.manufacturePartNumberA2.manufacture_note[i] != '') {

                                formData.append("manufacture_code[" + i + "]", context.manufacturePartNumberA2.manufacture_code[i]);
                                formData.append("mpn[" + i + "]", context.manufacturePartNumberA2.mpn[i]);
                                formData.append("source_type[" + i + "]", context.manufacturePartNumberA2.source_type[i]);
                                formData.append("manufacture_type[" + i + "]", context.manufacturePartNumberA2.manufacture_type[i]);
                                formData.append("manufacture_note[" + i + "]", context.manufacturePartNumberA2.manufacture_note[i]);

                            } else {
                                context.form.btnSave.onLoading = false;
                                context.$notify({
                                    message: this.tt("please_complete_data", { title: this.tt("mpn") }),                  
                                    type: 'danger'
                                });
                                return false;
                            }
                        }
                    }else {
                        formData.append("manufacture_code", '');
                        formData.append("mpn", '');
                        formData.append("source_type", '');
                        formData.append("manufacture_type", '');
                        formData.append("manufacture_note", '');
                    }
                    
                    // --------------------  FORM DATA EQUIPMENT & QUANTITY INSTALL ----------------------------
                    if (context.equipmentQuantityInstallA2.plant.length) {
                        for (var i=0; i<context.equipmentQuantityInstallA2.plant.length; i++) {
                            if (
                                context.equipmentQuantityInstallA2.equipment_code[i] != '' &&
                                context.equipmentQuantityInstallA2.qty_installed[i] != '' &&
                                context.equipmentQuantityInstallA2.drawing_number[i] != '') {
                                
                                formData.append("plant_equipment_code[" + i + "]",context.equipmentQuantityInstallA2.plant[i]);
                                formData.append("equipment_code[" + i + "]",context.equipmentQuantityInstallA2.equipment_code[i]);
                                formData.append("qty_installed[" + i + "]",context.equipmentQuantityInstallA2.qty_installed[i]);
                                formData.append("drawing_number[" + i + "]",context.equipmentQuantityInstallA2.drawing_number[i]);
                            } else {
                                context.form.btnSave.onLoading = false;
                                context.$notify({
                                    message: this.tt("please_complete_data", { title: this.tt("equipment_&_quantity_install") }),                  
                                    type: 'danger'
                                });
                                return false;
                            }
                        }
                    }else {
                        if (context.inputDraftListA2.bom_non_bom == 'BOM' || context.inputDraftListA2.item_type_code == 'OEM') {
                            context.$notify({
                                message: this.tt("please_complete_data", { title: this.tt("equipment_&_quantity_install") }),                  
                                type: 'danger'
                            });
                            context.form.btnSave.onLoading = false;
                            return false;
                        }else{
                            formData.append("plant_equipment_code", '');
                            formData.append("equipment_code", '');
                            formData.append("qty_installed", '');
                            formData.append("drawing_number", '');
                        }
                    }
                    
                    // --------------------  FORM DATA ATTACHMENT ----------------------------
                    if (context.prevAttachmentDocument.length || context.attachmentDocument.file_attachment_name.length) {
                        // // att prev
                        // for (var i = 0; i < context.prevAttachmentDocument.length; i++) {
                        //     formData.append("file_attachment_name_prev[" + i + "]", context.prevAttachmentDocument[i].file_attachment_name+'.'+context.prevAttachmentDocument[i].file_type);
                        // }

                        // att input
                        for (var i = 0; i < context.attachmentDocument.file_attachment_name.length; i++) {
                            if (context.attachmentDocument.file_attachment_name[i] != "" && context.attachmentDocument.description_attachment[i] != "") {
                                formData.append("file_attachment_name[" + i + "]", context.attachmentDocument.file_attachment_name[i]);
                                formData.append("description_attachment[" + i + "]", context.attachmentDocument.description_attachment[i]);
                            } else {
                                context.$notify({
                                  message: this.tt("please_complete_data", {
                                    title: this.tt("attachment_document")
                                  }),
                                  type: "danger"
                                });
                                context.form.btnSave.onLoading = false;
                                return false;
                            }
                        }
                    }else {
                        context.$notify({
                            message: this.tt("please_complete_data", { title: this.tt("attachment_document") }),                  
                            type: 'danger'
                        });
                        context.form.btnSave.onLoading = false;
                        return false;
                    }

                    let api = '';
                    if (save == 'add') {
                        api = Api(context, draftList.createNewMcrItemNoFormData(formData));
                    } else {
                        api = Api(context, draftList.updateMcrItemNoFormData(formData, context.inputDraftListA2.id));
                    }
                    api.onSuccess(function(response) {    
                        context.$notify({
                            message: response.data.message,                  
                            type: 'success',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                        context.form.btnSave.onLoading = false;
                        context.$router.back();
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.draftList = [];
                            context.form.btnSave.onLoading = false;
                        }
                    }).call()
                } else {
                    context.form.btnSave.onLoading = false;
                    context.$notify({
                        message: this.tt("please_complete_data", { title: this.tt("change_material") }),                  
                        type: 'danger'
                    });
                }
            },
            addMpnA2() {
                if (this.manufacturePartNumberA2.manufacture_code.length == 0) {
                    this.manufacturePartNumberA2.manufacture_code.push('')
                    this.manufacturePartNumberA2.mpn.push('')
                    this.manufacturePartNumberA2.manufacture_type.push('')
                    this.manufacturePartNumberA2.manufacture_note.push('')
                } else if (this.manufacturePartNumberA2.manufacture_code.length > 0) {
                    if (this.manufacturePartNumberA2.manufacture_code[this.manufacturePartNumberA2.manufacture_code.length-1] != '' &&
                        this.manufacturePartNumberA2.mpn[this.manufacturePartNumberA2.mpn.length-1] != '' &&
                        this.manufacturePartNumberA2.manufacture_type[this.manufacturePartNumberA2.manufacture_type.length-1] != '' &&
                        this.manufacturePartNumberA2.manufacture_note[this.manufacturePartNumberA2.manufacture_note.length-1] != '') {
                        
                        this.manufacturePartNumberA2.manufacture_code.push('')
                        this.manufacturePartNumberA2.mpn.push('')
                        this.manufacturePartNumberA2.manufacture_type.push('')
                        this.manufacturePartNumberA2.manufacture_note.push('')
                    } else {
                        this.$notify({
                            message: this.tt("please_complete_data", { title: this.tt("mpn") }),                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeMpnA2(key) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        this.manufacturePartNumberA2.manufacture_code.splice(key, 1)
                        this.manufacturePartNumberA2.mpn.splice(key, 1)
                        this.manufacturePartNumberA2.manufacture_type.splice(key, 1)
                        this.manufacturePartNumberA2.manufacture_note.splice(key, 1)
                    }
                })
            },
            addEqiA2() {
                if (this.equipmentQuantityInstallA2.plant.length == 0) {
                    this.equipmentQuantityInstallA2.plant.push('')
                    this.equipmentQuantityInstallA2.equipment_code.push('')
                    this.equipmentQuantityInstallA2.qty_installed.push('')
                    this.equipmentQuantityInstallA2.drawing_number.push('')
                } else if (this.equipmentQuantityInstallA2.plant.length > 0) {
                    if (this.equipmentQuantityInstallA2.plant[this.equipmentQuantityInstallA2.plant.length-1] != '' &&
                        this.equipmentQuantityInstallA2.equipment_code[this.equipmentQuantityInstallA2.equipment_code.length-1] != '' &&
                        this.equipmentQuantityInstallA2.qty_installed[this.equipmentQuantityInstallA2.qty_installed.length-1] != '' &&
                        this.equipmentQuantityInstallA2.drawing_number[this.equipmentQuantityInstallA2.drawing_number.length-1] != '') {
                        
                        this.equipmentQuantityInstallA2.plant.push('')
                        this.equipmentQuantityInstallA2.equipment_code.push('')
                        this.equipmentQuantityInstallA2.qty_installed.push('')
                        this.equipmentQuantityInstallA2.drawing_number.push('')
                    } else {
                        this.$notify({
                            message: this.tt("please_complete_data", {title: this.tt("equipment_&_quantity_install")}),                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeEqiA2(key) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        this.equipmentQuantityInstallA2.plant.splice(key, 1)
                        this.equipmentQuantityInstallA2.equipment_code.splice(key, 1)
                        this.equipmentQuantityInstallA2.qty_installed.splice(key, 1)
                        this.equipmentQuantityInstallA2.drawing_number.splice(key, 1)  
                    }
                })              
            },
            addAd() {

              if (this.attachmentDocument.file_attachment_name.length == 0) {
                this.attachmentDocument.file_attachment_name.push("");
                this.attachmentDocument.description_attachment.push("");
              } else if (this.attachmentDocument.file_attachment_name.length > 0) {
                if (
                  this.attachmentDocument.file_attachment_name[
                    this.attachmentDocument.file_attachment_name.length - 1
                  ] != "" &&
                  this.attachmentDocument.description_attachment[
                    this.attachmentDocument.description_attachment.length - 1
                  ] != "" 
                ) {
                    this.attachmentDocument.file_attachment_name.push("");
                    this.attachmentDocument.description_attachment.push("");
                } else {
                  this.$notify({
                    message: this.tt("please_complete_data", {title: this.tt("attachment_document")}),
                    type: "danger"
                  });
                }
              }
            },
            removeAd(key) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        this.attachmentDocument.file_attachment_name.splice(key, 1);
                        this.attachmentDocument.description_attachment.splice(key, 1);
                    }
                })
            },
            removePrevAd(id){
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, draftList.deleteMcrItemAttachment(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success'
                            });
                            context.getDetailMcrItem();
                        }).call();
                    }
                })
            },
            changeFileAttachmentName(files) {
                if (files[0].size > 2000000) {
                    alert("MAX SIZE 2 MB")
                    this.attachmentDocument == [];
                }else {
                    this.attachmentDocument.file_attachment_name[
                        this.attachmentDocument.file_attachment_name.length - 1
                    ] = files[0];
                }
            },
            mandatoryClient(key){
                let context = this;
                context.form.btnSave.onLoading = false;
                context.$notify({
                    message: this.tt("please_complete_data", { title: this.tt(key) }),                  
                    type: 'danger'
                });
            }
        }   
    };
</script>